import React from 'react';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// example how to use notification alert, no need to import component,just call where you have need,and put message in brackets.
// import { toast } from 'react-toastify';
// toast.info('message to write')
// toast.success('message to write')
// toast.warn('message to write')
// toast.error('message to write')

const ToastNotification = () => {
  return (
    <ToastContainer
      position='bottom-left'
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable={false}
      pauseOnHover={false}
      theme='light'
      transition={Slide}
    />
  );
};

export default ToastNotification;
