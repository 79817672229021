import React, { useLayoutEffect, useState } from 'react';
import { CssBaseline } from '@mui/material';
import MuiBox from '../../reusableComponents/MuiBox';
import WebView from './WebView';
import MobileView from './MobileView';

const NavBar = ({ children, location }) => {
  const [navColor, setNavColor] = useState(false);

  const [mobView, setMobView] = useState(true);

  window.addEventListener('scroll', () => {
    if (window.scrollY > 30) {
      setNavColor(true);
    } else {
      setNavColor(false);
    }
  });

  window.addEventListener('resize', () => {
    if (window.innerWidth > 650) {
      setMobView(false);
    } else {
      setMobView(true);
    }
  });

  useLayoutEffect(() => {
    if (window.innerWidth > 650) {
      setMobView(false);
    } else {
      setMobView(true);
    }
  }, []);
  return (
    <>
      <CssBaseline />

      {!mobView ? (
        <WebView navColor={navColor} />
      ) : (
        <MobileView
          navColor={navColor}
          location={location}
        />
      )}

      <MuiBox sx={{}}>{children}</MuiBox>
    </>
  );
};

export default NavBar;
