import React from 'react';
import { Typography, Tooltip } from '@mui/material';

const MuiTypography = (props) => {
  return (
    <Tooltip title={props.hint ? props.hint : ''}>
      <Typography {...props}>{props.text ? props.text : ''}</Typography>
    </Tooltip>
  );
};

export default MuiTypography;
