export const ruleForName = {
  required: { value: true, message: 'Polje je obavezno!!!' },
  pattern: {
    value: /[A-Za-z]/,
    message: 'Dozvoljena su samo slova.',
  },
  minLength: {
    value: 3,
    message: 'Minimalan broj slova je 4.',
  },
};

export const ruleForMobileNumber = {
  required: { value: true, message: 'Polje je obavezno!!!' },
  pattern: {
    value: /^[0-9]+$/,
    message: 'Dozvoljeni su samo brojevi.',
  },
  minLength: {
    value: 9,
    message: 'Broj telefona ne može sadržati manje od 9 cifara.',
  },
  maxLength: {
    value: 10,
    message: 'Broj telefona ne može sadržati više od 10 cifara.',
  },
};

export const ruleForEmail = {
  required: { value: true, message: 'Polje je obavezno!!!' },
  pattern: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    message: 'Neispravna imejl adresa.',
  },
  minLength: {
    value: 12,
    message: 'Minimalan broj karaktera je 12.',
  },
};

export const ruleForMessage = {
  required: { value: true, message: 'Polje je obavezno!!!' },
  minLength: {
    value: 20,
    message: 'Minimalan broj karaktera je 20.',
  },
};
