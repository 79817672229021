import React, { useEffect, useState } from 'react';
import MuiBox from '../../../reusableComponents/MuiBox';
import MuiTypography from '../../../reusableComponents/MuiTypography';
import { greyColorText, redColor, yellowColor } from '../../../helper/colors';
import MuiGrid from '../../../reusableComponents/MuiGrid';
import { v4 as uuidv4 } from 'uuid';
import { IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import Modal from '@mui/material/Modal';
import grupnaSlikaAutomobila from '../../../assets/automobili/78.webp';

import kamion from '../../../assets/kamioni/34.webp';

import motor1 from '../../../assets/motori/motor1.webp';
import motor2 from '../../../assets/motori/motor2.webp';
import motor3 from '../../../assets/motori/motor3.webp';

import auto1 from '../../../assets/automobili/auto1.webp';
import auto2 from '../../../assets/automobili/auto2.webp';
import auto3 from '../../../assets/automobili/auto3.webp';
import auto4 from '../../../assets/automobili/auto4.webp';
import auto5 from '../../../assets/automobili/auto5.webp';
import auto6 from '../../../assets/automobili/98.webp';
import auto7 from '../../../assets/automobili/117.webp';
import auto8 from '../../../assets/automobili/134.webp';
import { Helmet } from 'react-helmet';

let vozniParkMotora = [
  {
    img: motor1,
    nameOfVehicle: 'YAMAHA',
  },
  {
    img: motor2,
    nameOfVehicle: 'PIAGGIO BEVERLY 500',
  },
  {
    img: motor3,
    nameOfVehicle: 'SUZUKI BURGMAN 650',
  },
];

let vozniParkAutomobila = [
  {
    img: auto6,
    nameOfVehicle: 'SEAT IBIZA',
  },

  {
    img: auto8,
    nameOfVehicle: 'TOYOTA YARIS CROSS',
  },
  {
    img: auto1,
    nameOfVehicle: 'GOLF 7',
  },
  {
    img: auto2,
    nameOfVehicle: 'GOLF 7',
  },
  {
    img: auto3,
    nameOfVehicle: 'TOYOTA YARIS',
  },
  {
    img: auto5,
    nameOfVehicle: 'RENUALT CLIO',
  },
  {
    img: auto4,
    nameOfVehicle: 'RENUALT CLIO',
  },
  {
    img: auto7,
    nameOfVehicle: 'ASTRA K',
  },
];

let vozniParkKamiona = [
  {
    img: kamion,
    nameOfVehicle: 'MAN',
  },
];
const Vehicles = () => {
  const [zoomImage, setZoomImage] = useState('');
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const setImgAndOpenModal = (img) => {
    setZoomImage(img);
    handleOpen();
  };

  useEffect(() => {
    if (open === false) {
      setZoomImage('');
    }
  }, [open]);

  return (
    <MuiBox sx={{ padding: '0 0.5rem' }}>
      <Helmet>
        <title>Naša vozila - Auto škola DUŠAN START</title>
        <meta charSet='utf-8' />
        <meta
          name='description'
          content='Pogledajte naš vozni park u Auto školi DUŠAN START u Smederevu. Nudimo obuku na savremenim i tehnički ispravnim vozilima koja garantuju sigurnost i udobnost.'
        />
        <meta
          name='keywords'
          content='auto škola, vozni park, Dušan Start, Smederevo, moderna vozila, obuka, sigurnost, udobnost'
        />
        <meta
          name='author'
          content='Marko Stojanović'
        />
        <link
          rel='canonical'
          href='https://www.dusanstartautoskola.rs/vehicles'
        />

        <meta
          property='og:title'
          content='Naša vozila - Auto škola DUŠAN START'
        />
        <meta
          property='og:description'
          content='Pogledajte naš vozni park u Auto školi DUŠAN START u Smederevu. Nudimo obuku na savremenim i tehnički ispravnim vozilima koja garantuju sigurnost i udobnost.'
        />
        <meta
          property='og:image'
          content='%PUBLIC_URL%/logo512.png'
        />
        <meta
          property='og:url'
          content='https://www.dusanstartautoskola.rs/vehicles'
        />
        <meta
          property='og:type'
          content='website'
        />
        <meta
          property='og:site_name'
          content='Auto škola DUŠAN START'
        />
        <meta
          property='og:locale'
          content='sr_RS'
        />

        <meta
          name='twitter:card'
          content='summary_large_image'
        />
        <meta
          name='twitter:title'
          content='Naša vozila - Auto škola DUŠAN START'
        />
        <meta
          name='twitter:description'
          content='Pogledajte naš vozni park u Auto školi DUŠAN START u Smederevu. Nudimo obuku na savremenim i tehnički ispravnim vozilima koja garantuju sigurnost i udobnost.'
        />
        <meta
          name='twitter:image'
          content='%PUBLIC_URL%/logo512.png'
        />
        <meta
          name='twitter:site'
          content='@twitterhandle'
        />
        <meta
          name='twitter:creator'
          content='@twitterhandle'
        />
      </Helmet>

      <MuiBox
        sx={{
          backgroundImage: `url(${grupnaSlikaAutomobila})`,
          backgroundSize: 'cover',
          height: { xs: '400px', sm: '500px', md: '600px', lg: '600px' },
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}></MuiBox>
      <MuiTypography
        text='Nas vožni park'
        sx={{
          color: yellowColor,
          fontSize: {
            xs: '1.5rem',
            sm: '2rem',
            md: '2.8rem',
            lg: '3rem',
          },
          textAlign: 'center',
          margin: '1.5rem auto',
          fontWeight: 'bold',
        }}
      />
      <MuiBox
        sx={{
          padding: '1.5rem',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}>
        <MuiTypography
          text='Vozni park motora'
          sx={{
            color: redColor,
            fontSize: {
              xs: '1.5rem',
              sm: '1.8rem',
              md: '2.5rem',
              lg: '2.5rem',
            },
            textAlign: 'center',
            margin: '1.5rem auto',
          }}
        />
        <MuiTypography
          text='Sa ponosom ističemo da se u našem voznom parku nalaze motori isključivo novije generacije, tehnički ispravna, i udobna.'
          sx={{
            color: greyColorText,
            fontSize: {
              xs: '1.1rem',
              sm: '1.3rem',
              md: '1.5rem',
              lg: '1.5rem',
            },
            textAlign: 'center',
            margin: '1.5rem auto',
            fontStyle: 'italic',
          }}
        />
        <MuiGrid
          container
          sx={{
            padding: '1.5rem',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}>
          {vozniParkMotora.map((item) => (
            <MuiGrid
              xs={12}
              sm={6}
              md={4}
              lg={3}
              xl={3}
              key={uuidv4()}
              sx={{
                backgroundColor: '#fff',
                borderRadius: '1rem',
                width: '90%',
                boxShadow: '0 0 8px 3px #00000099',
                margin: '1rem',
                overflow: 'hidden',
              }}
              onClick={() => item?.img && setImgAndOpenModal(item?.img)}>
              <img
                loading='lazy'
                src={item.img}
                title={item.nameOfVehicle}
                alt={item.nameOfVehicle}
                className='vehicleImgStyle'
              />
              <MuiTypography
                text={item.nameOfVehicle}
                sx={{
                  color: yellowColor,
                  fontWeight: 'bold',
                  padding: '1rem',
                  position: 'relative',
                  zIndex: 55,
                  backgroundColor: '#fff',
                  fontSize: {
                    xs: '1.2rem',
                    sm: '1.4rem',
                    md: '1.7rem',
                    lg: '1.8rem',
                  },
                  textAlign: 'center',
                }}
              />
            </MuiGrid>
          ))}
        </MuiGrid>
      </MuiBox>

      <MuiBox
        sx={{
          padding: '1.5rem',
          justifyContent: 'space-around',
          alignItems: 'center',
          backgroundColor: yellowColor,
          boxShadow: '3px 6px 11px 2px #000000a3',
        }}>
        <MuiTypography
          text='Vozni park automobila'
          sx={{
            color: '#fff',
            fontSize: {
              xs: '1.5rem',
              sm: '1.8rem',
              md: '2.5rem',
              lg: '2.5rem',
            },
            textAlign: 'center',
            margin: '1.5rem auto',
          }}
        />
        <MuiTypography
          text='Sa ponosom ističemo da se u našem voznom parku nalaze vozila isključivo novije generacije, tehnički ispravna, udobna i klimatizovana.'
          sx={{
            color: '#fff',
            fontSize: {
              xs: '1.1rem',
              sm: '1.3rem',
              md: '1.5rem',
              lg: '1.5rem',
            },
            textAlign: 'center',
            margin: '1.5rem auto',
            fontStyle: 'italic',
          }}
        />
        <MuiGrid
          container
          sx={{
            padding: '1.5rem',
            justifyContent: 'space-around',
            alignItems: 'center',
            display: 'flex',
          }}>
          {vozniParkAutomobila.map((item) => (
            <MuiGrid
              xs={12}
              sm={6}
              md={4}
              lg={3}
              xl={3}
              key={uuidv4()}
              sx={{
                backgroundColor: '#fff',
                borderRadius: '1rem',
                width: '90%',
                boxShadow: '0 0 8px 3px #00000099',
                margin: '1rem',
                overflow: 'hidden',
              }}
              onClick={() => item?.img && setImgAndOpenModal(item?.img)}>
              <img
                loading='lazy'
                src={item.img}
                title={item.nameOfVehicle}
                alt={item.nameOfVehicle}
                className='vehicleImgStyle'
              />
              <MuiTypography
                text={item.nameOfVehicle}
                sx={{
                  color: yellowColor,
                  fontWeight: 'bold',
                  padding: '1rem',
                  position: 'relative',
                  zIndex: 55,
                  backgroundColor: '#fff',
                  fontSize: {
                    xs: '1.2rem',
                    sm: '1.4rem',
                    md: '1.7rem',
                    lg: '1.8rem',
                  },
                  textAlign: 'center',
                }}
              />
            </MuiGrid>
          ))}
        </MuiGrid>
      </MuiBox>
      <MuiBox
        sx={{
          padding: '1.5rem',
          justifyContent: 'space-around',
          alignItems: 'center',
          marginBottom: '1rem',
        }}>
        <MuiTypography
          text='Vozni park kamiona'
          sx={{
            color: redColor,
            fontSize: {
              xs: '1.5rem',
              sm: '1.8rem',
              md: '2.5rem',
              lg: '2.5rem',
            },
            textAlign: 'center',
            margin: '1.5rem auto',
          }}
        />
        <MuiTypography
          text='Sa ponosom ističemo da se u našem voznom parku nalaze vozila isključivo novije generacije, tehnički ispravna, udobna i klimatizovana.'
          sx={{
            color: greyColorText,
            fontSize: {
              xs: '1.1rem',
              sm: '1.3rem',
              md: '1.5rem',
              lg: '1.5rem',
            },
            textAlign: 'center',
            margin: '1.5rem auto',
            fontStyle: 'italic',
          }}
        />
        <MuiGrid
          container
          sx={{
            padding: '1.5rem',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}>
          {vozniParkKamiona.map((item) => (
            <MuiGrid
              xs={12}
              sm={6}
              md={4}
              lg={3}
              xl={3}
              key={uuidv4()}
              sx={{
                backgroundColor: '#fff',
                borderRadius: '1rem',
                width: '90%',
                boxShadow: '0 0 8px 3px #00000099',
                margin: '1rem',
                overflow: 'hidden',
              }}
              onClick={() => item?.img && setImgAndOpenModal(item?.img)}>
              <img
                loading='lazy'
                src={item.img}
                title={item.nameOfVehicle}
                alt={item.nameOfVehicle}
                className='vehicleImgStyle'
              />
              <MuiTypography
                text={item.nameOfVehicle}
                sx={{
                  color: yellowColor,
                  fontWeight: 'bold',
                  padding: '1rem',
                  position: 'relative',
                  zIndex: 55,
                  backgroundColor: '#fff',
                  fontSize: {
                    xs: '1.2rem',
                    sm: '1.4rem',
                    md: '1.7rem',
                    lg: '1.8rem',
                  },
                  textAlign: 'center',
                }}
              />
            </MuiGrid>
          ))}
        </MuiGrid>
      </MuiBox>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          sx={{
            boxShadow: '0 4px 30px #0000001a',
            backdropFilter: 'blur(5px)',
            transition: 'all 0.5s',
          }}>
          <div>
            <MuiBox
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: '#ffffff33',
              }}>
              <img
                loading='lazy'
                src={zoomImage}
                alt='zoom vehicle'
                className='zoomImageVehicle'
              />
              <IconButton
                size='large'
                onClick={() => handleClose()}
                sx={{
                  color: yellowColor,
                  position: 'absolute',
                  right: '1rem',
                  top: '1rem',
                  fontWeight: 'bold',
                  margin: 'auto',
                  display: 'flex',
                  alignItems: 'normal',
                  '&:hover': {
                    borderColor: yellowColor,
                    boxShadow: `0 0 5px 1px ${yellowColor}`,
                  },
                }}>
                <ClearIcon sx={{ fontSize: '3rem' }} />
              </IconButton>
            </MuiBox>
          </div>
        </Modal>
      </div>
    </MuiBox>
  );
};

export default Vehicles;
