import { AppBar, IconButton, Toolbar } from '@mui/material';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import { yellowColor } from '../../helper/colors';
import MuiBox from '../../reusableComponents/MuiBox';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import logo from '../../assets/logo512.png';

const WebView = ({ navColor }) => {
  let navigate = useNavigate();
  const [openAutoSkolaMenu, setOpenAutoSkolaMenu] = useState(false);
  const [openAutoSkolaMenuCategory, setOpenAutoSkolaMenuCategory] =
    useState(false);
  const [openAutoSkolaMenuTrainDriver, setOpenAutoSkolaMenuTrainDriver] =
    useState(false);
  return (
    <AppBar
      sx={
        navColor
          ? {
              background: yellowColor,
              transition: 'all 0.5s',
            }
          : {
              background: '#ffffff33',
              boxShadow: '0 4px 30px #0000001a',
              backdropFilter: 'blur(5px)',
              transition: 'all 0.5s',
            }
      }>
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: { xs: 0, sm: '1rem', md: 0, lg: 0 },
          flexDirection: {
            xs: 0,
            sm: 'column',
            md: 'row',
            lg: 'row',
          },
        }}>
        <Link to={'/'}>
          <img
            src={logo}
            loading='lazy'
            alt='logo'
            style={{ marginRight: '2.5rem', width: '8rem' }}
            onClick={() => navigate('/')}
          />
        </Link>

        <ul className='appBarMenuStyle'>
          <li>
            <HomeIcon
              fontSize='large'
              onClick={() => navigate('/')}
            />
          </li>
          <li
            onMouseEnter={() => setOpenAutoSkolaMenu(true)}
            onMouseLeave={() => setOpenAutoSkolaMenu(false)}>
            O auto školi
            <ul
              className={
                openAutoSkolaMenu
                  ? 'autoSkolaDivDropMenu autoSkolaDivDropMenuShow'
                  : 'autoSkolaDivDropMenu'
              }>
              <li>
                <Link to={'/aboutUs'}>O Nama</Link>
              </li>
              {/* <li>
                <Link to={'/ourTeam'}>Naš Tim</Link>
              </li> */}
              <li>
                <Link to={'/classroom'}>Učionica</Link>
              </li>
              <li>
                <Link to={'/vehicles'}>Vozila</Link>
              </li>
            </ul>
          </li>

          <li
            onMouseEnter={() => setOpenAutoSkolaMenuTrainDriver(true)}
            onMouseLeave={() => setOpenAutoSkolaMenuTrainDriver(false)}>
            Obuka vozača
            <ul
              className={
                openAutoSkolaMenuTrainDriver
                  ? 'autoSkolaDivDropMenu autoSkolaDivDropMenuShow'
                  : 'autoSkolaDivDropMenu'
              }>
              <li>
                <Link to={'/signinUp'}>Upis</Link>
              </li>
              <li>
                <Link to={'/theoreticalTraining'}>Teorijska obuka</Link>
              </li>
              <li>
                <Link to={'/medicalExamination'}>Lekarski pregled</Link>
              </li>
              <li>
                <Link to={'/practicalTraining'}>Praktična obuka</Link>
              </li>
              <li>
                <Link to={'/firstAid'}>Prva pomoć</Link>
              </li>
              <li>
                <Link to={'/additionalClasses'}>Dodatni časovi</Link>
              </li>
            </ul>
          </li>

          <li
            onMouseEnter={() => setOpenAutoSkolaMenuCategory(true)}
            onMouseLeave={() => setOpenAutoSkolaMenuCategory(false)}>
            Kategorije
            <ul
              className={
                openAutoSkolaMenuCategory
                  ? 'autoSkolaDivDropMenu autoSkolaDivDropMenuShow'
                  : 'autoSkolaDivDropMenu'
              }>
              <li>
                <Link to={`/categories/${'A'}`}>A Kategorija</Link>
              </li>
              <li>
                <Link to={`/categories/${'B'}`}>B Kategorija</Link>
              </li>
              <li>
                <Link to={`/categories/${'C'}`}>C Kategorija</Link>
              </li>
            </ul>
          </li>
          <li onClick={() => navigate('/contact')}>Kontakt</li>
          <li onClick={() => navigate('/checkIn')}>Prijavi se</li>
        </ul>

        <MuiBox
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: {
              xs: 'relative',
              sm: 'relative',
              md: 'absolute',
              lg: 'absolute',
            },
            right: 0,
          }}>
          <IconButton
            size='small'
            title='Posetite nas facebook profil'
            sx={{
              cursor: 'pointer',
            }}
            onClick={() =>
              window.open(
                'https://www.facebook.com/share/jm65Ft3suPEtuGi1/?mibextid=LQQJ4d',
                '_blank'
              )
            }>
            <FacebookIcon
              sx={{
                fontSize: '3rem',
                color: '#fff',
              }}
            />
          </IconButton>
          <IconButton
            size='small'
            title='Posetite nas instagram profil'
            sx={{
              cursor: 'pointer',
            }}
            onClick={() =>
              window.open(
                'https://www.instagram.com/autoskola_dusan_start?igsh=N3YxYXExcWdscjlj&utm_source=qr',
                '_blank'
              )
            }>
            <InstagramIcon
              sx={{
                fontSize: '3rem',
                color: '#fff',
              }}
            />
          </IconButton>
        </MuiBox>
      </Toolbar>
    </AppBar>
  );
};

export default WebView;
