import React from 'react';
import MuiBox from '../../reusableComponents/MuiBox';

let errorImage =
  'https://static.vecteezy.com/system/resources/thumbnails/013/076/891/small/error-page-not-found-vector.jpg';

const ErrorPage = () => {
  return (
    <MuiBox sx={{ padding: '0 0.5rem' }}>
      <MuiBox
        sx={{
          backgroundImage: `url(${errorImage})`,
          backgroundSize: 'cover',
          height: { xs: '400px', sm: '500px', md: '600px', lg: '600px' },
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}></MuiBox>
    </MuiBox>
  );
};

export default ErrorPage;
