import React from 'react';
import MuiBox from '../../../reusableComponents/MuiBox';
import MuiTypography from '../../../reusableComponents/MuiTypography';
import MuiGrid from '../../../reusableComponents/MuiGrid';
import MuiCard from '../../../reusableComponents/MuiCard';
import { CardContent, CardMedia } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { redColor, yellowColor } from '../../../helper/colors';
import MuiButton from '../../../reusableComponents/MuiButton';
import { useNavigate } from 'react-router-dom';

let banner =
  'https://alunno.rs/wp-content/uploads/2020/12/pheader-team.jpg.webp';

const timLjudi = [
  {
    img: 'https://alunno.rs/wp-content/uploads/2022/12/d.png.webp',
    fullName: 'Pera Peric',
    age: '18/05/1990',
    startWork: '25/07/2012',
    pozicija: 'Instruktor',
  },
  {
    img: 'https://alunno.rs/wp-content/uploads/2022/12/d.png.webp',
    fullName: 'Pera Peric',
    age: '18/05/1990',
    startWork: '25/07/2012',
    pozicija: 'Instruktor',
  },
  {
    img: 'https://alunno.rs/wp-content/uploads/2022/12/d.png.webp',
    fullName: 'Pera Peric',
    age: '18/05/1990',
    startWork: '25/07/2012',
    pozicija: 'Instruktor',
  },
  {
    img: 'https://alunno.rs/wp-content/uploads/2022/12/d.png.webp',
    fullName: 'Pera Peric',
    age: '18/05/1990',
    startWork: '25/07/2012',
    pozicija: 'Instruktor',
  },
  {
    img: 'https://alunno.rs/wp-content/uploads/2022/12/d.png.webp',
    fullName: 'Pera Peric',
    age: '18/05/1990',
    startWork: '25/07/2012',
    pozicija: 'Instruktor',
  },
  {
    img: 'https://alunno.rs/wp-content/uploads/2022/12/d.png.webp',
    fullName: 'Pera Peric',
    age: '18/05/1990',
    startWork: '25/07/2012',
    pozicija: 'Predavac',
  },
  {
    img: 'https://alunno.rs/wp-content/uploads/2022/12/d.png.webp',
    fullName: 'Pera Peric',
    age: '18/05/1990',
    startWork: '25/07/2012',
    pozicija: 'Direktor',
  },
  {
    img: 'https://alunno.rs/wp-content/uploads/2022/12/d.png.webp',
    fullName: 'Pera Peric',
    age: '18/05/1990',
    startWork: '25/07/2012',
    pozicija: 'Instruktor',
  },
  {
    img: 'https://alunno.rs/wp-content/uploads/2022/12/d.png.webp',
    fullName: 'Pera Peric',
    age: '18/05/1990',
    startWork: '25/07/2012',
    pozicija: 'Instruktor',
  },
  {
    img: 'https://alunno.rs/wp-content/uploads/2022/12/d.png.webp',
    fullName: 'Pera Peric',
    age: '6/05/1990',
    startWork: '25/07/2012',
    pozicija: 'Instruktor',
  },
];
const OurTeam = () => {
  const navigate = useNavigate();
  const calculateAge = (stringDate) => {
    const [day, month, year] = stringDate.split('/');

    const dateOfBirth = new Date(`${year}-${month}-${day}`);

    const currentDate = new Date();

    const differenceInMs = currentDate - dateOfBirth;

    const ageInMs = new Date(differenceInMs);

    const ageInYears = Math.abs(ageInMs.getUTCFullYear() - 1970);

    return ageInYears;
  };
  return (
    <MuiBox sx={{ padding: '0 0.5rem' }}>
      <MuiBox
        sx={{
          backgroundImage: `url(${banner})`,
          backgroundSize: 'cover',
          height: { xs: '400px', sm: '500px', md: '600px', lg: '600px' },
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}>
        <MuiTypography
          text='Nas Tim'
          sx={{
            color: '#fff',
            fontWeight: 'bold',
            fontSize: { xs: '1.5rem', sm: '1.8rem', md: '2rem', lg: '2.5rem' },
            textAlign: 'center',
            marginBottom: '1.5rem',
            textShadow: '2px 2px 5px rgba(0, 0, 0, 0.7)',
            WebkitTextStroke: '1px black',
            letterSpacing: '3px',
          }}
        />
      </MuiBox>
      <MuiGrid
        container
        sx={{
          padding: '1.5rem',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}>
        {timLjudi.map((item) => (
          <MuiGrid
            xs={12}
            sm={6}
            md={4}
            lg={3}
            xl={3}
            key={uuidv4()}
            sx={{ padding: '1rem' }}>
            <MuiCard sx={{ width: '100%' }}>
              <CardMedia
                sx={{ height: 340, border: `2px solid ${yellowColor}` }}
                image={item.img}
                title={item.fullName}
              />
              <CardContent>
                <MuiTypography
                  text={item.pozicija}
                  sx={{
                    color: yellowColor,
                    fontWeight: 'bold',
                    fontSize: {
                      xs: '1.2rem',
                      sm: '1.4rem',
                      md: '1.7rem',
                      lg: '1.8rem',
                    },
                    textAlign: 'center',
                  }}
                />
                <MuiTypography
                  text={item.fullName}
                  sx={{
                    color: '#000',
                    fontSize: {
                      xs: '1rem',
                      sm: '1.2rem',
                      md: '1.5rem',
                      lg: '1.5rem',
                    },
                    textAlign: 'center',
                  }}
                />
                <MuiTypography
                  text={calculateAge(item.age) + ' godina'}
                  sx={{
                    color: '#000',
                    fontSize: {
                      xs: '1rem',
                      sm: '1.2rem',
                      md: '1.5rem',
                      lg: '1.5rem',
                    },
                    textAlign: 'center',
                  }}
                />
                <MuiTypography
                  text={
                    'Deo naseg tima je vec ' +
                    calculateAge(item.startWork) +
                    ' godina.'
                  }
                  sx={{
                    color: '#000',
                    fontSize: {
                      xs: '1rem',
                      sm: '1.2rem',
                      md: '1.5rem',
                      lg: '1.5rem',
                    },
                    textAlign: 'center',
                  }}
                />
              </CardContent>
            </MuiCard>
          </MuiGrid>
        ))}
      </MuiGrid>
      <MuiBox
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '2rem',
        }}>
        <MuiButton
          variant='outlined'
          text='PRIJAVI SE ONLINE'
          size='large'
          onClick={() => navigate('/checkIn')}
          sx={{
            color: redColor,
            borderColor: redColor,
            fontWeight: 'bold',
            '&:hover': {
              borderColor: redColor,
              boxShadow: `0 0 5px 1px ${redColor}`,
            },
          }}
        />
      </MuiBox>
    </MuiBox>
  );
};

export default OurTeam;
