import React, { useEffect, useState } from 'react';
import { greyColorText, yellowColor } from '../../helper/colors';
import {
  AppBar,
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Toolbar,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import MuiBox from '../../reusableComponents/MuiBox';
import logo from '../../assets/logo512.png';

const MobileView = ({ navColor, location }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openAbout, setOpenAbout] = useState(false);
  const [openCategoryList, setOpenCategoryList] = useState(false);
  const [openTrainerDriver, setOpenTrainerDriver] = useState(false);

  const handleClickAbout = () => {
    setOpenAbout(!openAbout);
    setOpenCategoryList(false);
    setOpenTrainerDriver(false);
  };
  const handleClickCategoryList = () => {
    setOpenCategoryList(!openCategoryList);
    setOpenTrainerDriver(false);
    setOpenAbout(false);
  };
  const handleClickTrainerDriver = () => {
    setOpenTrainerDriver(!openTrainerDriver);
    setOpenAbout(false);
    setOpenCategoryList(false);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  useEffect(() => {
    setOpen(false);
  }, [location.pathname]);

  return (
    <>
      <AppBar
        sx={
          navColor
            ? { background: yellowColor, transition: 'all 0.5s' }
            : {
                background: '#ffffff33',
                boxShadow: '0 4px 30px #0000001a',
                backdropFilter: 'blur(5px)',
                transition: 'all 0.5s',
              }
        }>
        <Toolbar sx={{}}>
          <MenuIcon
            fontSize='large'
            onClick={toggleDrawer(true)}
          />
          <Link
            style={{
              display: 'block',
              margin: 'auto',
            }}
            to={'/'}>
            <img
              loading='lazy'
              src={logo}
              alt='logo'
              style={{ width: '7rem' }}
            />
          </Link>
        </Toolbar>
      </AppBar>

      <Drawer
        open={open}
        onClose={toggleDrawer(false)}>
        <List
          sx={{
            width: '100%',
            maxWidth: 360,
            minWidth: 250,
            bgcolor: 'background.paper',
          }}
          component='nav'
          aria-labelledby='nested-list-subheader'
          subheader={
            <ListSubheader
              component='div'
              sx={{
                display: 'flex',
                borderBottom: '1px solid #d3d3d3',
                width: '80%',
                margin: 'auto',
              }}>
              <img
                loading='lazy'
                src={logo}
                alt='logo'
                style={{ margin: '1rem auto', width: '6rem' }}
                onClick={() => navigate('/')}
              />
            </ListSubheader>
          }>
          <ListItemButton
            component={Link}
            to={'/'}>
            <ListItemText primary='Početna' />
          </ListItemButton>
          <ListItemButton onClick={handleClickAbout}>
            <ListItemText primary='O auto školi' />
            {openAbout ? (
              <ExpandLess style={{ marginLeft: '1rem' }} />
            ) : (
              <ExpandMore style={{ marginLeft: '1rem' }} />
            )}
          </ListItemButton>
          <Collapse
            in={openAbout}
            timeout='auto'
            unmountOnExit>
            <List
              component='div'
              disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to={'/aboutUs'}>
                <ListItemText primary='O Nama' />
              </ListItemButton>
              {/* <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to={'/ourTeam'}>
                <ListItemText primary='Naš Tim' />
              </ListItemButton> */}
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to={'/classroom'}>
                <ListItemText primary='Učionica' />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to={'/vehicles'}>
                <ListItemText primary='Vozila' />
              </ListItemButton>
            </List>
          </Collapse>
          <ListItemButton onClick={handleClickCategoryList}>
            <ListItemText primary='Kategorije' />
            {openCategoryList ? (
              <ExpandLess style={{ marginLeft: '1rem' }} />
            ) : (
              <ExpandMore style={{ marginLeft: '1rem' }} />
            )}
          </ListItemButton>
          <Collapse
            in={openCategoryList}
            timeout='auto'
            unmountOnExit>
            <List
              component='div'
              disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to={`/categories/${'A'}`}>
                <ListItemText primary='A Kategorija' />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to={`/categories/${'B'}`}>
                <ListItemText primary='B Kategorija' />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to={`/categories/${'C'}`}>
                <ListItemText primary='C Kategorija' />
              </ListItemButton>
            </List>
          </Collapse>
          <ListItemButton
            component={Link}
            to={'/contact'}>
            <ListItemText primary='Kontakt' />
          </ListItemButton>
          <ListItemButton
            component={Link}
            to={'/checkIn'}>
            <ListItemText primary='Prijavi se' />
          </ListItemButton>
          <ListItemButton onClick={handleClickTrainerDriver}>
            <ListItemText primary='Obuka vozača' />
            {openTrainerDriver ? (
              <ExpandLess style={{ marginLeft: '1rem' }} />
            ) : (
              <ExpandMore style={{ marginLeft: '1rem' }} />
            )}
          </ListItemButton>
          <Collapse
            in={openTrainerDriver}
            timeout='auto'
            unmountOnExit>
            <List
              component='div'
              disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to={'/signinUp'}>
                <ListItemText primary='Upis' />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to={'/theoreticalTraining'}>
                <ListItemText primary='Teorijska obuka' />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to={'/medicalExamination'}>
                <ListItemText primary='Lekarski pregled' />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to={'/practicalTraining'}>
                <ListItemText primary='Praktična obuka' />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to={'/firstAid'}>
                <ListItemText primary='Prva pomoć' />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to={'/additionalClasses'}>
                <ListItemText primary='Dodatni časovi' />
              </ListItemButton>
            </List>
          </Collapse>
        </List>
        <MuiBox
          sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            backgroundColor: greyColorText,
          }}>
          <IconButton
            size='small'
            title='Posetite nas facebook profil'
            sx={{
              cursor: 'pointer',
            }}
            onClick={() =>
              window.open(
                'https://www.facebook.com/share/jm65Ft3suPEtuGi1/?mibextid=LQQJ4d',
                '_blank'
              )
            }>
            <FacebookIcon
              sx={{
                fontSize: '3rem',
                color: '#fff',
              }}
            />
          </IconButton>
          <IconButton
            size='small'
            title='Posetite nas instagram profil'
            sx={{
              cursor: 'pointer',
            }}
            onClick={() =>
              window.open(
                'https://www.instagram.com/autoskola_dusan_start?igsh=N3YxYXExcWdscjlj&utm_source=qr',
                '_blank'
              )
            }>
            <InstagramIcon
              sx={{
                fontSize: '3rem',
                color: '#fff',
              }}
            />
          </IconButton>
        </MuiBox>
      </Drawer>
    </>
  );
};

export default MobileView;
