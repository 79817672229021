import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const Slider = (props) => {
  const buttonStyle = {
    width: '30px',
    background: 'none',
    border: '0px',
  };
  const properties = {
    prevArrow: (
      <button style={{ ...buttonStyle, left: '5px' }}>
        <ArrowForwardIcon
          sx={{
            fontSize: { xs: '2rem', sm: '2.4rem', md: '2.8rem', lg: '3rem' },
            color: '#fff',
            transform: 'rotate(180deg)',
            boxShadow: '0 0 4px 1px black',
            borderRadius: '50%',
            position: 'relative',
            left: { xs: '0.4rem', sm: '0.4rem', md: '0.5rem', lg: '0.5rem' },
            transition: 'all 0.5s ease',
            '&:hover': {
              backgroundColor: '#80808069',
              transform: 'rotate(180deg) scale(1.1)',
            },
          }}
        />
      </button>
    ),
    nextArrow: (
      <button style={{ ...buttonStyle, right: '5px' }}>
        <ArrowForwardIcon
          sx={{
            fontSize: { xs: '2rem', sm: '2.4rem', md: '2.8rem', lg: '3rem' },
            color: '#fff',
            position: 'relative',
            right: { xs: '0.6rem', sm: '0.7rem', md: '1.2rem', lg: '1.5rem' },
            boxShadow: '0 0 4px 1px black',
            borderRadius: '50%',
            transition: 'all 0.5s ease',
            '&:hover': {
              backgroundColor: '#80808069',
              transform: 'scale(1.1)',
            },
          }}
        />
      </button>
    ),
  };
  return (
    <Slide
      autoplay={true}
      infinite={true}
      pauseOnHover={true}
      defaultIndex={0}
      duration={2500}
      arrows={true}
      {...properties}
      {...props}>
      {props.children}
    </Slide>
  );
};

export default Slider;
