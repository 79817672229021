import React from 'react';
import { Button, Tooltip } from '@mui/material';

const MuiButton = (props) => {
  return (
    <Tooltip title={props.hint ? props.hint : ''}>
      <Button {...props}>{props.text ? props.text : 'No text'}</Button>
    </Tooltip>
  );
};

export default MuiButton;
