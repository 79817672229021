import React, { useState } from 'react';
import MuiBox from '../../reusableComponents/MuiBox';
import MuiTypography from '../../reusableComponents/MuiTypography';
import { useForm } from 'react-hook-form';
import FormInputHook from '../../reusableComponents/FormInputHook';
import MuiGrid from '../../reusableComponents/MuiGrid';
import {
  ruleForEmail,
  ruleForMessage,
  ruleForMobileNumber,
  ruleForName,
} from '../../helper/rules';
import { greyColorText } from '../../helper/colors';
import emailjs from '@emailjs/browser';
import { publicKey, serviceId, templateId } from '../../helper/emailJs';
import { toast } from 'react-toastify';
import prijavaBanner from '../../assets/banners/prijavaBanner.webp';
import { Helmet } from 'react-helmet';

const CheckIn = ({ location }) => {
  const [loader, setLoader] = useState(false);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      message: '',
      hiddenField: '',
      categorie: '',
    },
  });

  const onSubmit = (e, data) => {
    setLoader(true);

    if (data?.target[13]?.name === 'hiddenField') {
      if (data?.target[13]?.value === '') {
        emailjs
          .sendForm(serviceId, templateId, data.target, {
            publicKey: publicKey,
          })
          .then(
            (res) => {
              if (res?.text === 'OK') {
                reset();
                setLoader(false);
                toast.success('Poruka je uspešno poslata.');
              }
            },
            (error) => {
              setLoader(false);
              toast.error(
                'Poruka nije poslata, desila se neka greška prilikom slanja emaila.'
              );
            }
          );
      }
    } else {
      reset();
      setLoader(false);
      toast.error('Poruka nije poslata, spamer je aktivan!!!');
    }
  };

  return (
    <MuiBox sx={{ padding: '0 0.5rem' }}>
      {location?.pathname === '/checkIn' && (
        <Helmet>
          <title>Prijava za obuku - Auto škola DUŠAN START</title>
          <meta charSet='utf-8' />
          <meta
            name='description'
            content='Prijavite se za obuku ili dodatne časove vožnje u Auto školi DUŠAN START u Smederevu. Postanite siguran i odgovoran vozač uz našu stručnu obuku.'
          />
          <meta
            name='keywords'
            content='auto škola, Dušan Start, Smederevo, prijava, obuka, dodatni časovi, vozačka dozvola, vozački ispit, vozačka škola'
          />
          <meta
            name='author'
            content='Marko Stojanovic'
          />
          <link
            rel='canonical'
            href='https://www.dusanstartautoskola.rs/checkIn'
          />

          <meta
            property='og:title'
            content='Prijava za obuku - Auto škola DUŠAN START'
          />
          <meta
            property='og:description'
            content='Prijavite se za obuku ili dodatne časove vožnje u Auto školi DUŠAN START u Smederevu. Postanite siguran i odgovoran vozač uz našu stručnu obuku.'
          />
          <meta
            property='og:image'
            content='https://www.dusanstartautoskola.rs/logo512.png'
          />
          <meta
            property='og:url'
            content='https://www.dusanstartautoskola.rs/checkIn'
          />
          <meta
            property='og:type'
            content='website'
          />
          <meta
            property='og:site_name'
            content='Auto škola DUŠAN START'
          />
          <meta
            property='og:locale'
            content='sr_RS'
          />

          <meta
            name='twitter:card'
            content='summary_large_image'
          />
          <meta
            name='twitter:title'
            content='Prijava za obuku - Auto škola DUŠAN START'
          />
          <meta
            name='twitter:description'
            content='Prijavite se za obuku ili dodatne časove vožnje u Auto školi DUŠAN START u Smederevu. Postanite siguran i odgovoran vozač uz našu stručnu obuku.'
          />
          <meta
            name='twitter:image'
            content='https://www.dusanstartautoskola.rs/logo512.png'
          />
        </Helmet>
      )}

      {location?.pathname === '/checkIn' && (
        <MuiBox
          sx={{
            backgroundImage: `url(${prijavaBanner})`,
            backgroundSize: 'cover',
            height: { xs: '400px', sm: '500px', md: '600px', lg: '600px' },
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}>
          <MuiTypography
            text='Online formular za prijavu kandidata za polaganje za A, A1, A2, AM, B, C, CE kategorija'
            sx={{
              color: '#fff',
              fontSize: {
                xs: '1.3rem',
                sm: '1.5rem',
                md: '1.5rem',
                lg: '1.8rem',
              },
              textAlign: 'center',
              marginBottom: '1.5rem',
              textShadow: '2px 2px 5px rgba(0, 0, 0, 0.7)',
              WebkitTextStroke: '1px black',
              fontWeight: 'bold',
            }}
          />
          <MuiTypography
            text='Kontaktirajte nas'
            sx={{
              color: '#fff',
              fontSize: {
                xs: '2rem',
                sm: '2.8rem',
                md: '3.2rem',
                lg: '3.5rem',
              },
              textAlign: 'center',
              marginBottom: '1.5rem',
              textShadow: '2px 2px 5px rgba(0, 0, 0, 0.7)',
              WebkitTextStroke: '1px black',
              fontWeight: 'bold',
            }}
          />
          <MuiTypography
            text='Online prijava za obuku i doobuku vozača'
            sx={{
              color: '#fff',
              fontSize: {
                xs: '1.3rem',
                sm: '1.5rem',
                md: '1.5rem',
                lg: '1.8rem',
              },
              textAlign: 'center',
              marginBottom: '1.5rem',
              textShadow: '2px 2px 5px rgba(0, 0, 0, 0.7)',
              WebkitTextStroke: '1px black',
              fontWeight: 'bold',
            }}
          />
        </MuiBox>
      )}

      <MuiBox sx={{ padding: '2rem 1rem', width: '100%' }}>
        <MuiTypography
          text='Popunite formular / prijavu'
          sx={{
            color: greyColorText,
            fontSize: {
              xs: '1.5rem',
              sm: '1.7rem',
              md: '1.8rem',
              lg: '2rem',
            },
            textAlign: 'center',
            marginBottom: '1.5rem',
            fontWeight: 'bold',
          }}
        />
        <MuiTypography
          text='*kontaktiracemo sve kandidate u najkraćem roku po prijemu zahteva!'
          sx={{
            color: greyColorText,
            fontSize: {
              xs: '1rem',
              sm: '1.2rem',
              md: '1.4rem',
              lg: '1.5rem',
            },
            textAlign: 'center',
            marginBottom: '1.5rem',
            fontWeight: 'bold',
          }}
        />
      </MuiBox>

      <MuiBox sx={{ padding: '2rem 1rem', width: '100%' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <MuiGrid
            container
            spacing={4}
            rowSpacing={4}
            sx={{
              justifyContent: 'space-around',
              width: { xs: '100%', sm: '90%', md: '85%', lg: '80%', xl: '70%' },
              margin: 'auto',
              alignItems: 'center',
            }}>
            <MuiGrid
              xs={12}
              sm={6}
              md={6}
              lg={5}
              xl={5}
              sx={{ paddingTop: 0 }}>
              <FormInputHook
                name='firstName'
                control={control}
                label='Ime'
                multiline={false}
                validation={ruleForName}
                errors={errors}
              />
            </MuiGrid>
            <MuiGrid
              xs={12}
              sm={6}
              md={6}
              lg={5}
              xl={5}
              sx={{ paddingTop: 0 }}>
              <FormInputHook
                name='lastName'
                control={control}
                label='Prezime'
                multiline={false}
                validation={ruleForName}
                errors={errors}
              />
            </MuiGrid>
            <MuiGrid
              xs={12}
              sm={6}
              md={6}
              lg={5}
              xl={5}
              sx={{ paddingTop: 0 }}>
              <FormInputHook
                name='phoneNumber'
                control={control}
                label='Mob.telefon'
                multiline={false}
                validation={ruleForMobileNumber}
                errors={errors}
              />
            </MuiGrid>
            <MuiGrid
              xs={12}
              sm={6}
              md={6}
              lg={5}
              xl={5}
              sx={{ paddingTop: 0 }}>
              <FormInputHook
                name='email'
                control={control}
                label='Email'
                multiline={false}
                validation={ruleForEmail}
                errors={errors}
              />
            </MuiGrid>
            <MuiGrid
              xs={12}
              sm={6}
              md={6}
              lg={5}
              xl={5}
              sx={{ paddingTop: 0 }}>
              <FormInputHook
                name='categorie'
                control={control}
                label='Odaberite kategoriju koju želite da polažete'
                multiline={false}
                validation={{}}
                errors={errors}
                selectField={true}
              />
            </MuiGrid>
            <MuiGrid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ paddingTop: 0 }}>
              <FormInputHook
                name='message'
                control={control}
                label='Poruka za nas'
                multiline={true}
                validation={ruleForMessage}
                errors={errors}
              />
            </MuiGrid>

            <MuiGrid
              xs={12}
              sm={6}
              md={6}
              lg={5}
              xl={5}
              sx={{ visibility: 'hidden', position: 'absolute', top: '-500%' }}>
              <FormInputHook
                name='hiddenField'
                control={control}
                label='hiddenField'
                multiline={false}
                validation={{}}
                errors={errors}
              />
            </MuiGrid>
          </MuiGrid>
          <input
            type='submit'
            value='Posalji mail'
            className='subbmitBtn'
          />
        </form>
      </MuiBox>
      {loader && (
        <div className='loaderCon'>
          <span className='loader'></span>
        </div>
      )}
    </MuiBox>
  );
};

export default CheckIn;
