import React from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';

// xs sm md lg xl

const MuiGrid = (props) => {
  return <Grid2 {...props}>{props.children}</Grid2>;
};

export default MuiGrid;
