import React, { useEffect, useState } from 'react';
import { CardContent, Hidden, IconButton } from '@mui/material';
import Slider from '../../reusableComponents/Slider';
import { v4 as uuidv4 } from 'uuid';
import MuiTypography from '../../reusableComponents/MuiTypography';
import { greyColorText, redColor, yellowColor } from '../../helper/colors';
import MuiGrid from '../../reusableComponents/MuiGrid';
import MuiBox from '../../reusableComponents/MuiBox';
import MuiButton from '../../reusableComponents/MuiButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import phoneIcon from '../../assets/phone.svg';
import infoIcon from '../../assets/info.svg';
import PersonIcon from '@mui/icons-material/Person';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PaidIcon from '@mui/icons-material/Paid';
import MuiCard from '../../reusableComponents/MuiCard';
import motor1 from '../../assets/motori/motor1.webp';
import auto1 from '../../assets/automobili/auto1.webp';
import auto2 from '../../assets/banners/IMG_6512.webp';
import ucionica1 from '../../assets/ucionica/ucionica1.webp';
import ucionica2 from '../../assets/ucionica/ucionica3.webp';
import DriveParkSLider from '../DriveParkSLider';
import CheckIn from '../CheckIn/CheckIn';
import grupnaAuto5 from '../../assets/banners/5.webp';
import grupnaAuto78 from '../../assets/banners/78.webp';
import kamion from '../../assets/kamioni/34.webp';
import auto130 from '../../assets/banners/130.webp';
import StepsForTrain from './StepsForTrain';
import { Helmet } from 'react-helmet';

const homeSliderData = [
  {
    img: grupnaAuto5,
    text1: `<div><div><span class='titleOfCategireSlider bigerTitle spaceBettweLetters'>Obuka vozača</span></div><div class='flexDir'><span class='spanHoverText'>A</span><span class='spanHoverText'>A1</span><span class='spanHoverText'>A2</span><span class='spanHoverText'>B</span><span class='spanHoverText'>C</span><span class='spanHoverText'>CE</span></div><span class='titleOfCategireSlider firstImgBla'>Auto škola DUŠAN START</span></div>`,
    text2: ``,
  },
  {
    img: auto130,
    text1: `<span class='titleOfCategireSlider toyotaBigerFont marginBottom'>Novi član našeg voznog parka</span>`,
    text2: `<span class='titleOfCategireSlider'>Toyota Yaris Cross 2022</span>`,
  },

  {
    img: auto2,
    text1: `<span class='titleOfCategireSlider toyotaBigerFont marginBottom'>Izaberite jedno od 8 vozila koje imamo u našoj ponudi</span>`,
    text2: `<a
        href='/vehicles'
        class='titleOfCategireSlider toyotaBigerFont'>
        Pogledajte naša vozila
      </a>`,
  },
  {
    img: grupnaAuto78,
    text1: `<span class='titleOfCategireSlider toyotaBigerFont marginBottom16'>Krenite sa obukom odmah a platite na 10 mesečnih rata</span>`,
    text2: `<span class='titleOfCategireSlider toyotaBigerFont'>80% kandidata je upisano po vašoj preporuci!!!</span>`,
  },
];

const homeCategoriesToPractice = [
  {
    img: motor1,
    text: 'A, A1, A2, AM kategorije',
    hoverText:
      'Auto škola "Dušan Start" je uvela za svoje kandidate A kategorije mogućnost da izaberu jel će da polažu na automatskom ili manuelnom menjaču.',
    path: '/categories/A',
  },
  {
    img: auto1,
    text: 'B kategorija',
    hoverText:
      'Nova, moderna i bezbedna vozila, savremenije učionice i profesionalni instruktori garantuju svakom kandidatu da se oseća bezbedno i lagodno u toku celog programa polaganja vozačkog ispita.',
    path: '/categories/B',
  },
  {
    img: kamion,
    text: 'C i CE kategorija',
    hoverText:
      'Prijavu za polaganje C ili CE kategorije možete izvršiti na lokaciji Miloša Velikog 15, Smederevo 11300.',
    path: '/categories/C',
  },
];

const classRoomImages = [
  {
    img: ucionica1,
  },
  {
    img: ucionica2,
  },
];

const whyUsDivCon = [
  {
    icon: 'person',
    headerTitle: 'Iskusni instruktori',
    text: '8 iskusnih instruktora koji će Vam pomoći da lako savladate sve prepreke u saobraćaju.',
    path: '/signinUp',
  },
  {
    icon: 'car',
    headerTitle: 'Nova vozila',
    text: 'Možemo da se pohvalimo flotom novih i sigurnih vozila, kao što je na primer Toyota Yaris Cross 2022.',
    path: '/vehicles',
  },
  {
    icon: 'education',
    headerTitle: 'Vrhunska edukacija',
    text: 'Naši predavači teorijske obuke će Vam pomoći da potpuno spremni izađete na test polaganja.',
    path: '/classroom',
  },
  {
    icon: 'money',
    headerTitle: 'Plaćanje na rate',
    text: 'Plaćanje na rate, platnim karticama, čekovima građana, administrativna zabrana na 12 rata.',
    path: '/checkIn',
  },
];

const icons = {
  person: PersonIcon,
  car: DirectionsCarIcon,
  education: ApartmentIcon,
  money: PaidIcon,
};

const HomePage = () => {
  const navigate = useNavigate();
  const [isTextVisible, setIsTextVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsTextVisible(true);
    }, 600);

    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, []);
  return (
    <MuiBox
      sx={{
        padding: '0 0.5rem',
      }}>
      <Helmet>
        <title>AUTO ŠKOLA DUŠAN START</title>
        <meta charSet='utf-8' />
        <meta
          name='description'
          content='Auto škola Dušan Start u Smederevu nudi sve informacije o polaganju vozačkog ispita i upisu na časove vožnje. Pogledajte naša vozila i saznajte kako do vozačke dozvole.'
        />
        <meta
          name='keywords'
          content='auto škola, Dušan Start, Smederevo, polaganje vozačkog ispita, vozačka dozvola, časovi vožnje, vozila, upis, vožnja, instruktor vožnje, vozačka dozvola Smederevo, vozačke lekcije, školovanje za vozača, vozački test, auto škola Smederevo'
        />
        <meta
          name='author'
          content='Marko Stojanović'
        />
        <link
          rel='canonical'
          href='https://www.dusanstartautoskola.rs/'
        />

        <meta
          property='og:title'
          content='AUTO ŠKOLA DUŠAN START'
        />
        <meta
          property='og:description'
          content='Auto škola Dušan Start u Smederevu nudi sve informacije o polaganju vozačkog ispita i upisu na časove vožnje. Pogledajte naša vozila i saznajte kako do vozačke dozvole.'
        />
        <meta
          property='og:image'
          content='%PUBLIC_URL%/logo512.png'
        />
        <meta
          property='og:url'
          content='https://www.dusanstartautoskola.rs/'
        />
        <meta
          property='og:type'
          content='website'
        />
        <meta
          property='og:site_name'
          content='Auto škola Dušan Start'
        />
        <meta
          property='og:locale'
          content='sr_RS'
        />

        <meta
          name='twitter:card'
          content='summary_large_image'
        />
        <meta
          name='twitter:title'
          content='AUTO ŠKOLA DUŠAN START'
        />
        <meta
          name='twitter:description'
          content='Auto škola Dušan Start u Smederevu nudi sve informacije o polaganju vozačkog ispita i upisu na časove vožnje. Pogledajte naša vozila i saznajte kako do vozačke dozvole.'
        />
        <meta
          name='twitter:image'
          content='%PUBLIC_URL%/logo512.png'
        />
        <meta
          name='twitter:site'
          content='@dusanstart'
        />
        <meta
          name='twitter:creator'
          content='@dusanstart'
        />
      </Helmet>
      <Slider>
        {homeSliderData.map((item) => (
          <div
            className='each-slide-effect'
            key={uuidv4()}>
            <div
              style={{ backgroundImage: `url(${item.img})` }}
              className='homeSliderDivCon'>
              {isTextVisible && (
                <Hidden smDown>
                  <span dangerouslySetInnerHTML={{ __html: item.text1 }}></span>
                  {item.text1 ===
                  `<span class='titleOfCategireSlider'>Izaberite jedno od 8 vozila koje imamo u ponudi, i naučite da vozite na vozilima najnovije generacije.</span>` ? (
                    <span>{item.text2}</span>
                  ) : (
                    <span
                      dangerouslySetInnerHTML={{ __html: item.text2 }}></span>
                  )}
                </Hidden>
              )}
            </div>
          </div>
        ))}
      </Slider>
      <MuiBox sx={{ backgroundColor: yellowColor }}>
        <MuiTypography
          text='KATEGORIJE KOJE MOŽETE POLAGATI U NAŠOJ AUTO ŠKOLI'
          sx={{
            color: '#fff',
            fontWeight: 'bold',
            fontSize: { xs: '1rem', sm: '1.2rem', md: '1.5rem', lg: '2rem' },
            textAlign: 'center',
            padding: '1.5rem 1REM',
            margin: '0.5rem auto',
          }}
        />
      </MuiBox>
      <MuiGrid
        container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}>
        {homeCategoriesToPractice.map((item, index) => (
          <MuiGrid
            key={uuidv4()}
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              backgroundImage: `url(${item.img})`,
              width: '100%',
              height: '350px',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              position: 'relative',
              zIndeks: 10,
              overflow: 'hidden',
            }}
            onMouseEnter={() =>
              (document.getElementById('zaHover' + index).style.top = 0)
            }
            onMouseLeave={() =>
              (document.getElementById('zaHover' + index).style.top = '-100%')
            }>
            <MuiTypography
              text={item.text}
              sx={{
                color: '#fff',
                fontWeight: 'bold',
                textShadow: '0 0 4px black',
                fontSize: {
                  xs: '1rem',
                  sm: '1rem',
                  md: '1.5rem',
                  lg: '2rem',
                },
                textAlign: 'center',
                padding: '1rem 0',
                background: '#bfb6b699',
              }}
            />
            <MuiBox
              id={'zaHover' + index}
              sx={{
                position: 'absolute',
                backgroundColor: '#80808075',
                width: '100%',
                height: '100%',
                top: '-100%',
                zIndex: 5,
                transition: 'all 1s ease',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}>
              <MuiTypography
                text={item?.hoverText}
                sx={{
                  color: '#fff',
                  fontWeight: 'bold',
                  fontSize: {
                    xs: '0.9rem',
                    sm: '1rem',
                    md: '1.3rem',
                    lg: '1.5rem',
                  },
                  textAlign: 'center',
                  padding: '1rem 0',
                  textShadow: '0 0 black',
                }}
              />
              <MuiButton
                variant='outlined'
                text={'Saznaj više'}
                size='large'
                endIcon={<ArrowForwardIcon />}
                onClick={() => navigate(item.path)}
                sx={{
                  color: yellowColor,
                  borderColor: yellowColor,
                  fontWeight: 'bold',
                  borderWidth: '2px',
                  backgroundColor: '#f0f8ffa1',
                  borderRadius: '0.5rem',
                  transition: 'all 0.5s ease',
                  '&:hover': {
                    borderColor: yellowColor,
                    borderWidth: '2px',
                    backgroundColor: '#f0f8ffa1',
                    borderRadius: '0.5rem',
                    transform: 'scale(1.1)',
                    fontSize: '14.5px',
                  },
                }}
              />
            </MuiBox>
          </MuiGrid>
        ))}
      </MuiGrid>
      <MuiGrid
        container
        sx={{
          backgroundColor: yellowColor,
          padding: '1rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '0.3rem auto',
        }}>
        <MuiTypography
          text='NOVA GRUPA KREĆE USKORO'
          sx={{
            color: '#fff',
            fontWeight: 'bold',
            fontSize: {
              xs: '1rem',
              sm: '1rem',
              md: '1.5rem',
              lg: '2rem',
            },
            width: '100%',
            textAlign: 'center',
            marginBottom: '1rem',
          }}
        />
        <MuiTypography
          text='UPIŠI SE I ZAUZMI SVOJE MESTO'
          sx={{
            color: '#fff',
            fontWeight: 'bold',
            fontSize: {
              xs: '1rem',
              sm: '1rem',
              md: '1.5rem',
              lg: '2rem',
            },
            width: '100%',
            textAlign: 'center',
            marginBottom: '1rem',
          }}
        />
        <MuiGrid
          container
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            width: '100%',
          }}>
          <MuiGrid
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}>
            <MuiTypography
              text='Predavanja radnim danima'
              sx={{
                color: '#fff',
                fontWeight: 'bold',
                fontSize: {
                  xs: '1rem',
                  sm: '1rem',
                  md: '1.5rem',
                  lg: '2rem',
                },
                width: '100%',
                textAlign: 'center',
                marginBottom: '1rem',
              }}
            />
            <MuiTypography
              text='- Pre podne 09 h'
              sx={{
                color: '#fff',
                fontWeight: 'bold',
                fontSize: {
                  xs: '1rem',
                  sm: '1rem',
                  md: '1.5rem',
                  lg: '2rem',
                },
                width: '100%',
                textAlign: 'center',
                marginBottom: '1rem',
              }}
            />
            <MuiTypography
              text='- Po podne 17:30 h'
              sx={{
                color: '#fff',
                fontWeight: 'bold',
                fontSize: {
                  xs: '1rem',
                  sm: '1rem',
                  md: '1.5rem',
                  lg: '2rem',
                },
                width: '100%',
                textAlign: 'center',
                marginBottom: '1rem',
              }}
            />
          </MuiGrid>
          <MuiGrid
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
            <MuiBox
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                padding: '1rem',
              }}>
              <img
                loading='lazy'
                src={phoneIcon}
                alt='info icon'
                style={{
                  borderRadius: '50%',
                  border: '2px solid #fff',
                  padding: '1rem',
                }}
                onClick={() => window.open('tel:+3810666225333')}
              />

              <MuiTypography
                text='info 066 6225 333 '
                sx={{
                  color: '#fff',
                  fontWeight: 'bold',
                  fontSize: {
                    xs: '1rem',
                    sm: '1rem',
                    md: '1.5rem',
                    lg: '2rem',
                  },
                  width: '100%',
                  textAlign: 'center',
                  marginTop: '1rem',
                }}
              />
            </MuiBox>
            <MuiBox
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                padding: '1rem',
                cursor: 'pointer',
                transition: 'all 0.5s ease',
                '&:hover': {
                  boxShadow: '0 0 6px 2px #ffffffb8',
                  borderRadius: '0.5rem',
                },
              }}
              onClick={() => navigate('/checkIn')}>
              <img
                loading='lazy'
                src={infoIcon}
                alt='info icon'
                style={{
                  borderRadius: '50%',
                  border: '2px solid #fff',
                  padding: '1rem',
                }}
              />

              <MuiTypography
                text='ONLINE PRIJAVA'
                sx={{
                  color: '#fff',
                  fontWeight: 'bold',
                  fontSize: {
                    xs: '1rem',
                    sm: '1rem',
                    md: '1.5rem',
                    lg: '2rem',
                  },
                  width: '100%',
                  textAlign: 'center',
                  marginTop: '1rem',
                }}
              />
            </MuiBox>
          </MuiGrid>
        </MuiGrid>
      </MuiGrid>
      <MuiGrid
        container
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          width: '100%',
          marginTop: '1.5rem',
        }}>
        <MuiGrid
          xs={12}
          sm={12}
          md={5}
          lg={5}
          xl={5}
          className='homeClassrom'>
          <Slider>
            {classRoomImages.map((item) => (
              <MuiBox
                sx={{}}
                key={uuidv4()}>
                <img
                  loading='lazy'
                  src={item.img}
                  alt='classroom'
                  style={{ width: '100%', aspectRatio: '1.3/1' }}
                />
              </MuiBox>
            ))}
          </Slider>
        </MuiGrid>
        <MuiGrid
          xs={12}
          sm={12}
          md={5}
          lg={5}
          xl={5}>
          <MuiTypography
            text='AUTO ŠKOLA DUŠAN START'
            sx={{
              color: yellowColor,
              fontWeight: 'bold',
              fontSize: {
                xs: '1.2rem',
                sm: '1.4rem',
                md: '1.5rem',
                lg: '2rem',
              },
              marginBottom: '1.5rem',
              marginTop: { xs: '1rem', sm: '1rem', md: 0, lg: 0 },
            }}
          />
          <MuiTypography
            text='DOBRODOŠLI'
            sx={{
              color: greyColorText,
              fontSize: {
                xs: '1.1rem',
                sm: '1.2rem',
                md: '1.3rem',
                lg: '1.8rem',
              },
              marginBottom: '1.2rem',
            }}
          />
          <MuiTypography
            text='Dođite da se upoznamo! Očekujemo Vas u prijatnom ambijentu u auto školi DUŠAN START sa besprekorno opremljenim učionicama, sa visikostručnim i ljubaznim osobljem i sa savremenim vozilima isključivo novije generacije, tehnički ispravna, udobna i klimatizovana.'
            sx={{
              color: greyColorText,
              marginBottom: '1.2rem',
              fontStyle: 'italic',
            }}
          />
          <MuiTypography
            text='Nalazimo se na adresi:'
            sx={{
              color: greyColorText,
              fontSize: '1.1rem',
              marginBottom: '1rem',
            }}
          />
          <MuiTypography
            text='Miloša Velikog 15, Smederevo 11300'
            sx={{
              color: greyColorText,
              fontSize: '1.1rem',
              marginBottom: '1rem',
            }}
          />
          <MuiTypography
            text='Broj kancelarije:'
            sx={{
              color: greyColorText,
              fontSize: '1.1rem',
              marginBottom: '0.3rem',
            }}
          />
          <Hidden smUp>
            <p
              style={{
                color: redColor,
                fontSize: '1.1rem',
                marginBottom: '1rem',
              }}>
              <a
                href={`tel:+381649445346`}
                style={{ color: redColor }}>
                +381 64 94-45-346
              </a>
            </p>
          </Hidden>
          <Hidden smDown>
            <MuiTypography
              text='+381 64 94-45-346'
              sx={{
                color: redColor,
                fontSize: '1.1rem',
                marginBottom: '1rem',
              }}
            />
          </Hidden>

          <MuiTypography
            text='Direktor škole:'
            sx={{
              color: greyColorText,
              fontSize: '1.1rem',
              marginBottom: '0.3rem',
            }}
          />
          <Hidden smDown>
            <MuiTypography
              text='+381 66 66-225-333'
              sx={{
                color: redColor,
                fontSize: '1.1rem',
                marginBottom: '1rem',
              }}
            />
          </Hidden>
          <Hidden smUp>
            <p
              style={{
                color: redColor,
                fontSize: '1.1rem',
                marginBottom: '1rem',
              }}>
              <a
                href={`tel:+3816666225333`}
                style={{ color: redColor }}>
                +381 66 66-225-333
              </a>
            </p>
          </Hidden>

          <MuiTypography
            text='Kontakt Informacije:'
            sx={{
              color: greyColorText,
              fontSize: '1.1rem',
              marginBottom: '0.3rem',
            }}
          />
          <Hidden smDown>
            <MuiTypography
              text='+381 64 36-20-725'
              sx={{
                color: redColor,
                fontSize: '1.1rem',
                marginBottom: '1rem',
              }}
            />
          </Hidden>
          <Hidden smUp>
            <p
              style={{
                color: redColor,
                fontSize: '1.1rem',
                marginBottom: '1rem',
              }}>
              <a
                href={`tel:+381643620725`}
                style={{ color: redColor }}>
                +381 64 36-20-725
              </a>
            </p>
          </Hidden>
        </MuiGrid>
      </MuiGrid>

      <MuiGrid
        container
        sx={{
          justifyContent: 'space-around',
          textAlign: 'center',
          backgroundColor: yellowColor,
          padding: '1rem',
          boxShadow: '3px 6px 11px 2px #000000a3',
          marginTop: '1.5rem',
        }}>
        <MuiGrid
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}>
          <MuiTypography
            text='AUTO ŠKOLA DUŠAN START POSLE 10 GODINA RADA'
            sx={{
              margin: '1rem auto 2rem',
              fontSize: {
                xs: '1.2rem',
                sm: '1.2rem',
                md: '1.3rem',
                lg: '1.8rem',
              },
              fontWeight: 'bold',
              color: '#fff',
            }}
          />
        </MuiGrid>
        <MuiGrid
          xs={12}
          sm={12}
          md={2.8}
          lg={2.8}
          xl={2.8}
          sx={{
            border: '2px solid #fff',
            borderRadius: '1rem',
            padding: '0.5rem',
            marginTop: '1rem',
            transition: 'all 0.5s linear',
          }}>
          <MuiTypography
            text='8'
            sx={{
              fontSize: {
                xs: '1.2rem',
                sm: '1.2rem',
                md: '1.3rem',
                lg: '1.8rem',
              },
              fontWeight: 'bold',
              color: '#fff',
            }}
          />
          <MuiTypography
            text='ISKUSNIH INSTRUKTORA'
            sx={{
              marginTop: '1rem',
              fontSize: {
                xs: '1.1rem',
                sm: '1.2rem',
                md: '1.3rem',
                lg: '1.8rem',
              },
              color: '#fff',
            }}
          />
        </MuiGrid>
        <MuiGrid
          xs={12}
          sm={12}
          md={2.8}
          lg={2.8}
          xl={2.8}
          sx={{
            border: '2px solid #fff',
            borderRadius: '1rem',
            padding: '0.5rem',
            marginTop: '1rem',
          }}>
          <MuiTypography
            text='5500'
            sx={{
              fontSize: {
                xs: '1.2rem',
                sm: '1.2rem',
                md: '1.3rem',
                lg: '1.8rem',
              },
              fontWeight: 'bold',
              color: '#fff',
            }}
          />
          <MuiTypography
            text='BROJ KANDIDATA'
            sx={{
              marginTop: '1rem',
              fontSize: {
                xs: '1.1rem',
                sm: '1.2rem',
                md: '1.3rem',
                lg: '1.8rem',
              },
              color: '#fff',
            }}
          />
        </MuiGrid>
        <MuiGrid
          xs={12}
          sm={12}
          md={2.8}
          lg={2.8}
          xl={2.8}
          sx={{
            border: '2px solid #fff',
            borderRadius: '1rem',
            padding: '0.5rem',
            marginTop: '1rem',
          }}>
          <MuiTypography
            text='12'
            sx={{
              fontSize: {
                xs: '1.2rem',
                sm: '1.2rem',
                md: '1.3rem',
                lg: '1.8rem',
              },
              fontWeight: 'bold',
              color: '#fff',
            }}
          />
          <MuiTypography
            text='SAVREMENIH VOZILA'
            sx={{
              marginTop: '1rem',
              fontSize: {
                xs: '1.1rem',
                sm: '1.2rem',
                md: '1.3rem',
                lg: '1.8rem',
              },
              color: '#fff',
            }}
          />
        </MuiGrid>
        <MuiGrid
          xs={12}
          sm={12}
          md={2.8}
          lg={2.8}
          xl={2.8}
          sx={{
            border: '2px solid #fff',
            borderRadius: '1rem',
            padding: '0.5rem',
            marginTop: '1rem',
          }}>
          <MuiTypography
            text='10'
            sx={{
              fontSize: {
                xs: '1.2rem',
                sm: '1.2rem',
                md: '1.3rem',
                lg: '1.8rem',
              },
              fontWeight: 'bold',
              color: '#fff',
            }}
          />
          <MuiTypography
            text='GODINA POSTOJANJA'
            sx={{
              marginTop: '1rem',
              fontSize: {
                xs: '1.1rem',
                sm: '1.2rem',
                md: '1.3rem',
                lg: '1.8rem',
              },
              color: '#fff',
            }}
          />
        </MuiGrid>
      </MuiGrid>
      <MuiGrid
        container
        sx={{ justifyContent: 'center' }}>
        <MuiTypography
          text='Koraci prilikom obuke'
          sx={{
            marginTop: '2.5rem',
            marginBottom: '1.5rem',
            fontSize: {
              xs: '1.8rem',
              sm: '2rem',
              md: '2.3rem',
              lg: '2.5rem',
            },
            color: redColor,
            fontWeight: 'bold',
            width: '100%',
            textAlign: 'center',
          }}
        />
        <StepsForTrain />
        <MuiBox
          sx={{
            backgroundColor: yellowColor,
            width: '100%',
            marginTop: '3rem',
            boxShadow: '3px 6px 11px 2px #000000a3',
          }}>
          <MuiTypography
            text='Teorijska obuka se završava za 13 radnih dana!!!'
            sx={{
              marginTop: '1.5rem',
              marginBottom: '1.5rem',
              padding: '0 0.5rem',
              fontSize: {
                xs: '1.6rem',
                sm: '1.8rem',
                md: '2rem',
                lg: '2.2rem',
              },
              color: '#fff',
              fontWeight: 'bold',
              width: '100%',
              textAlign: 'center',
              fontStyle: 'italic',
            }}
          />
        </MuiBox>

        <MuiTypography
          text='Kreni sa obukom odmah, a plati na 10 rata!'
          sx={{
            marginTop: '1rem',
            marginBottom: '1.5rem',
            fontSize: {
              xs: '1.6rem',
              sm: '1.8rem',
              md: '2rem',
              lg: '2.2rem',
            },
            color: redColor,
            fontWeight: 'bold',
            width: '100%',
            textAlign: 'center',
            fontStyle: 'italic',
          }}
        />

        <MuiTypography
          text='Priušti sebi najbolje, jer naš cilj je bezbedan vozač...'
          sx={{
            marginTop: '1rem',
            marginBottom: {
              xs: '3rem',
              sm: '2.5rem',
              md: '2rem',
              lg: '2rem',
            },
            fontSize: {
              xs: '1.8rem',
              sm: '2rem',
              md: '2.3rem',
              lg: '2.5rem',
            },
            color: redColor,
            fontWeight: 'bold',
            width: '100%',
            textAlign: 'center',
          }}
        />
      </MuiGrid>
      <DriveParkSLider />
      <MuiBox
        sx={{
          textAlign: 'center',
          padding: '1rem',
          margin: '0.3rem auto',
        }}>
        <MuiTypography
          text='AUTO ŠKOLA DUŠAN START'
          sx={{
            fontSize: {
              xs: '1rem',
              sm: '1.2rem',
              md: '1.2rem',
              lg: '1.5rem',
            },
            color: yellowColor,
          }}
        />
        <MuiTypography
          text='Zašto baš mi?'
          sx={{
            marginTop: '1rem',
            fontSize: {
              xs: '1.8rem',
              sm: '2rem',
              md: '2.2rem',
              lg: '2.4rem',
            },
            color: greyColorText,
            letterSpacing: '2px',
            fontWeight: 'bold',
          }}
        />
        <MuiTypography
          text='Moderan vozni park sa 8 automobila, 4 motocikla i kamion sa prikolicom.'
          sx={{
            marginTop: '1rem',
            fontSize: {
              xs: '1.1rem',
              sm: '1.3rem',
              md: '1.3rem',
              lg: '1.5rem',
            },
            color: greyColorText,
            fontStyle: 'italic',
          }}
        />
        <MuiTypography
          text='Konstatno obnavljanje voznog parka!!!'
          sx={{
            marginTop: '1rem',
            fontSize: {
              xs: '1.1rem',
              sm: '1.3rem',
              md: '1.3rem',
              lg: '1.5rem',
            },
            color: greyColorText,
            fontStyle: 'italic',
          }}
        />
        <MuiTypography
          text='Licencirani predavači, ispitivači i instruktori.'
          sx={{
            marginTop: '1rem',
            fontSize: {
              xs: '1.1rem',
              sm: '1.3rem',
              md: '1.3rem',
              lg: '1.5rem',
            },
            color: greyColorText,
            fontStyle: 'italic',
          }}
        />
        <MuiTypography
          text='Kontinuirana teorijska nastava, predavanja i priprema za polaganje putem školskog softvera i interneta.'
          sx={{
            marginTop: '1rem',
            fontSize: {
              xs: '1.1rem',
              sm: '1.3rem',
              md: '1.3rem',
              lg: '1.5rem',
            },
            color: greyColorText,
            fontStyle: 'italic',
          }}
        />
        <MuiTypography
          text='Termini nastave i vožnje prilagođeni kandidatima!'
          sx={{
            marginTop: '1rem',
            fontSize: {
              xs: '1.1rem',
              sm: '1.3rem',
              md: '1.3rem',
              lg: '1.5rem',
            },
            color: greyColorText,
            fontStyle: 'italic',
          }}
        />

        <MuiTypography
          text='Široke i fleksibilne mogućnosti plaćanja'
          sx={{
            marginTop: '1rem',
            fontSize: {
              xs: '1.1rem',
              sm: '1.3rem',
              md: '1.3rem',
              lg: '1.5rem',
            },
            color: greyColorText,
            fontStyle: 'italic',
          }}
        />

        <MuiGrid
          container
          sx={{
            marginTop: '3.5rem',
            marginBottom: '2rem',
            display: 'flex',
            justifyContent: 'space-around',
          }}>
          {whyUsDivCon.map((item) => {
            const IconComponent = icons[item.icon];
            return (
              <MuiGrid
                xs={12}
                sm={12}
                md={5.5}
                lg={5.5}
                xl={5.5}
                key={uuidv4()}
                data-aos='zoom-in-down'
                data-aos-easing='linear'
                data-aos-duration='500'
                sx={{
                  padding: {
                    xs: '2rem 1.5rem',
                    sm: '2rem 1.5rem',
                    md: '1.5rem',
                    lg: '1.5rem',
                  },
                  position: 'relative',
                }}>
                <MuiCard
                  sx={{
                    width: '100%',
                    border: `thin solid #66666680`,
                    boxShadow: '5px 5px 13px 5px #0000005c',
                    minHeight: {
                      xs: '160px',
                      sm: '160px',
                      md: '205px',
                      lg: '205px',
                    },
                    height: '100%',
                    paddingTop: {
                      xs: '2rem',
                      sm: '2rem',
                      md: 0,
                      lg: 0,
                    },
                    paddingLeft: {
                      xs: 0,
                      sm: '1.5rem',
                      md: '3rem',
                      lg: '3rem',
                    },
                    margin: 'auto',
                    position: 'relative',
                    overflow: 'visible',
                  }}>
                  <IconButton
                    size='small'
                    sx={{
                      position: 'absolute',
                      width: {
                        xs: '5rem',
                        sm: '5rem',
                        md: '7rem',
                        lg: '7rem',
                      },
                      background: yellowColor,
                      borderRadius: '0.5rem',
                      left: {
                        xs: '50%',
                        sm: '50%',
                        md: 0,
                        lg: 0,
                      },
                      top: {
                        xs: 0,
                        sm: 0,
                        md: '50%',
                        lg: '50%',
                      },
                      transform: 'translate(-50%, -50%)',
                      transition: 'all 0.5s ease',
                      '&:hover': {
                        background: yellowColor,
                        boxShadow: '0 0 11px 6px #837676a6',
                        transform: 'translate(-50%, -50%) scale(1.05)',
                      },
                      cursor: 'pointer',
                    }}
                    onClick={() => navigate(`${item.path}`)}>
                    <IconComponent
                      sx={{
                        fontSize: {
                          xs: '4rem',
                          sm: '4rem',
                          md: '6rem',
                          lg: '6rem',
                        },
                        color: '#fff',
                      }}
                    />
                  </IconButton>
                  <CardContent>
                    <MuiTypography
                      text={item.headerTitle}
                      sx={{
                        color: yellowColor,
                        fontWeight: 'bold',
                        fontStyle: 'italic',
                        fontSize: {
                          xs: '1.2rem',
                          sm: '1.4rem',
                          md: '1.7rem',
                          lg: '1.8rem',
                        },
                        textAlign: {
                          xs: 'center',
                          sm: 'center',
                          md: 'left',
                          lg: 'left',
                        },
                        marginBottom: '1rem',
                      }}
                    />
                    <MuiTypography
                      text={item.text}
                      sx={{
                        fontSize: {
                          xs: '1.2rem',
                          sm: '1.4rem',
                          md: '1.7rem',
                          lg: '1.8rem',
                        },
                        textAlign: {
                          xs: 'center',
                          sm: 'left',
                          md: 'left',
                          lg: 'left',
                        },
                        color: greyColorText,
                      }}
                    />
                  </CardContent>
                </MuiCard>
              </MuiGrid>
            );
          })}
        </MuiGrid>
      </MuiBox>
      <CheckIn />
    </MuiBox>
  );
};

export default HomePage;
