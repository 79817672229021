import React from 'react';
import MuiBox from '../../../reusableComponents/MuiBox';
import MuiTypography from '../../../reusableComponents/MuiTypography';
import { greyColorText, redColor, yellowColor } from '../../../helper/colors';
import MuiButton from '../../../reusableComponents/MuiButton';
import { v4 as uuidv4 } from 'uuid';
import mainOffice from '../../../assets/ucionica/mainOffice.webp';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const goals = [
  'Osigurati da svaki polaznik stekne potrebno znanje i veštine za bezbednu vožnju.',
  'Razviti svest o važnosti poštovanja saobraćajnih propisa.',
  'Pripremiti ih za samostalno upravljanje vozilom u različitim uslovima.',
  'Te ih osnažiti da budu odgovorni učesnici u saobraćaju',
  'Da podstičemo razvoj samopouzdanja kod kandidata i da im pružimo konstantnu podršku tokom celog procesa obuke',
];

const difrents = [
  'Individualnom pristupu svakom kandidatu.',
  'Stručnom i iskusnom instruktorskom timu.',
  'Modernom prostoru i za teorijsku obuku i nove racunare za rad kandidata.',
  'Moderan vozni park i sigurna vozila najnovije generacije.',
  'Teorijska obuka se u nasoj skoli zavrsava samo za 13 radnih dana.',
  'Kandidati imaju mogućnost izbora instruktora ili vozila za praktičnu obuku.',
  'Termini nastave i vožnje prilagođeni kandidatima.',
  'Široke i fleksibilne mogućnosti plaćanja.',
];

const AboutUs = () => {
  const navigate = useNavigate();
  return (
    <MuiBox sx={{ padding: '0 0.5rem' }}>
      <Helmet>
        <meta charSet='utf-8' />
        <title>O Auto Školi Dušan Start | Auto Škola Smederevo</title>
        <meta
          name='description'
          content='Auto škola Dušan Start iz Smedereva nudi profesionalnu obuku budućih vozača sa individualnim pristupom i modernim vozilima. Saznajte više o našim ciljevima i razlikama u pristupu obuci.'
        />
        <meta
          name='keywords'
          content='auto škola, Dušan Start, Smederevo, vozačka dozvola, obuka vozača, teorijska obuka, praktična obuka, vozački ispit, instruktori vožnje'
        />
        <meta
          name='author'
          content='Marko Stojanovic'
        />
        <link
          rel='canonical'
          href='https://www.dusanstartautoskola.rs/aboutUs'
        />

        {/* Open Graph Meta Tagovi */}
        <meta
          property='og:title'
          content='O Auto Školi Dušan Start | Auto Škola Smederevo'
        />
        <meta
          property='og:description'
          content='Auto škola Dušan Start iz Smedereva nudi profesionalnu obuku budućih vozača sa individualnim pristupom i modernim vozilima. Saznajte više o našim ciljevima i razlikama u pristupu obuci.'
        />
        <meta
          property='og:image'
          content='https://www.dusanstartautoskola.rs/logo512.png'
        />
        <meta
          property='og:url'
          content='https://www.dusanstartautoskola.rs/aboutUs'
        />
        <meta
          property='og:type'
          content='website'
        />
        <meta
          property='og:site_name'
          content='Auto Škola Dušan Start'
        />
        <meta
          property='og:locale'
          content='sr_RS'
        />

        {/* Twitter Card Meta Tagovi */}
        <meta
          name='twitter:card'
          content='summary_large_image'
        />
        <meta
          name='twitter:title'
          content='O Auto Školi Dušan Start | Auto Škola Smederevo'
        />
        <meta
          name='twitter:description'
          content='Auto škola Dušan Start iz Smedereva nudi profesionalnu obuku budućih vozača sa individualnim pristupom i modernim vozilima. Saznajte više o našim ciljevima i razlikama u pristupu obuci.'
        />
        <meta
          name='twitter:image'
          content='https://www.dusanstartautoskola.rs/logo512.png'
        />
        <meta
          name='twitter:site'
          content='@twitterhandle'
        />
        <meta
          name='twitter:creator'
          content='@twitterhandle'
        />
      </Helmet>

      <MuiBox
        sx={{
          backgroundImage: `url(${mainOffice})`,
          backgroundSize: 'cover',
          height: { xs: '400px', sm: '500px', md: '600px', lg: '600px' },
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}>
        <MuiTypography
          text='Mi stvaramo prave vozače'
          sx={{
            color: '#fff',
            fontWeight: 'bold',
            fontSize: { xs: '1.2rem', sm: '1.2rem', md: '1.5rem', lg: '2rem' },
            textAlign: 'center',
            marginBottom: '1.5rem',
            textShadow: '2px 2px 5px rgba(0, 0, 0, 0.7)',
            WebkitTextStroke: '1px black',
          }}
        />
        <MuiTypography
          text='O Auto Školi DUŠAN START'
          sx={{
            color: '#fff',
            fontWeight: 'bold',
            fontSize: { xs: '1.5rem', sm: '1.5rem', md: '2rem', lg: '2.3rem' },
            textAlign: 'center',
            marginBottom: '1.5rem',
            textShadow: '2px 2px 5px rgba(0, 0, 0, 0.7)',
            WebkitTextStroke: '1px black',
          }}
        />
        <MuiTypography
          text={`Auto škola DUŠAN START veruje da svaki kandidat zaslužuje kvalitetan i profesionalan program obuke kako bi postao vrhunski vozač.`}
          sx={{
            color: '#fff',
            fontWeight: 'bold',
            fontSize: { xs: '1.2rem', sm: '1.2rem', md: '1.5rem', lg: '2rem' },
            textAlign: 'center',
            textShadow: '2px 2px 5px rgba(0, 0, 0, 0.7)',
            WebkitTextStroke: '1px black',
          }}
        />
      </MuiBox>
      <MuiBox
        sx={{
          padding: '2rem',
          margin: '2rem auto',
          width: { xs: '95%', sm: '90%', md: '80%', lg: '80%' },
        }}>
        <MuiTypography
          text='Auto škola Dušan Start iz Smedereva je pouzdana institucija sa dugogodišnjim iskustvom u obuci budućih vozača. Nudi individualni pristup svakom polazniku, kvalitetnu teorijsku i praktičnu obuku, kao i stručne instruktore koji će vam pomoći da sigurno savladate veštine vožnje. Svojim kvalitetom rada i visokim standardima, auto škola Dušan Start izdvaja se kao odličan izbor za sve one koji žele da postanu odgovorni i vešti vozači.'
          sx={[
            textStyle,
            {
              '&:first-letter': {
                color: yellowColor,
                fontSize: '1.5rem',
                letterSpacing: '1px',
              },
            },
          ]}
        />
        <MuiTypography
          text='Ciljevi auto škole prilikom obuke kandidata su :'
          sx={[
            textStyle,
            {
              marginBottom: '1rem',
              marginTop: '1rem',
              fontWeight: 'bold',
              fontSize: {
                xs: '1.2rem',
                sm: '1.2rem',
                md: '1.4rem',
                lg: '1.4rem',
              },
            },
          ]}
        />
        {goals.map((item) => (
          <MuiTypography
            key={uuidv4()}
            text={`- ${item}`}
            sx={[
              textStyle,
              {
                textIndent: { xs: 0, sm: 0, md: '1rem', lg: '1rem' },
              },
            ]}
          />
        ))}

        <MuiTypography
          text='Razlikujemo se od drugih auto škola u Smederevu'
          sx={[
            textStyle,
            {
              marginBottom: '1rem',
              marginTop: '1rem',
              fontWeight: 'bold',
              fontSize: {
                xs: '1.2rem',
                sm: '1.2rem',
                md: '1.4rem',
                lg: '1.4rem',
              },
            },
          ]}
        />
        {difrents.map((item) => (
          <MuiTypography
            key={uuidv4()}
            text={`- ${item}`}
            sx={[
              textStyle,
              {
                textIndent: { xs: 0, sm: 0, md: '1rem', lg: '1rem' },
              },
            ]}
          />
        ))}

        <MuiTypography
          text='Takođe, naglasak stavljamo na bezbednost u saobraćaju i pružanje kvalitetne obuke kako bismo osposobili kandidate da postanu sigurni i bezbedni vozači.'
          sx={[
            textStyle,
            {
              marginBottom: '1.5rem',
              marginTop: '1.5rem',
              fontWeight: 'bold',
              textAlign: 'center',
              fontSize: {
                xs: '1.2rem',
                sm: '1.2rem',
                md: '1.4rem',
                lg: '1.4rem',
              },
            },
          ]}
        />

        <MuiTypography
          text='Cak 80% kandidata upisano je po vasoj preporuci'
          sx={[
            textStyle,
            {
              marginBottom: '1.5rem',
              marginTop: '1.5rem',
              fontWeight: 'bold',
              textAlign: 'center',
              fontSize: {
                xs: '1.2rem',
                sm: '1.2rem',
                md: '1.4rem',
                lg: '1.4rem',
              },
            },
          ]}
        />

        <MuiTypography
          text='HVALA NA UKAZANOM POVERENJU'
          sx={[
            textStyle,
            {
              marginBottom: '1.5rem',
              color: redColor,
              marginTop: '1.5rem',
              fontWeight: 'bold',
              textAlign: 'center',
              fontSize: {
                xs: '1.4rem',
                sm: '1.6rem',
                md: '1.7rem',
                lg: '1.8rem',
              },
            },
          ]}
        />

        <MuiTypography
          text='Zadovoljni kandidati su najbolji  pokazatelj kvaliteta nase  auto škole. Kvalitetna obuka i pažnja prema potrebama svakog pojedinačnog kandidata su ključni faktori koji našu  auto školu čine  posebnom i istaknutom u odnosu na druge.'
          sx={[
            textStyle,
            {
              marginBottom: '1.5rem',
              marginTop: '1.5rem',
              fontWeight: 'bold',
              textAlign: 'center',
              fontSize: {
                xs: '1.2rem',
                sm: '1.2rem',
                md: '1.4rem',
                lg: '1.4rem',
              },
              '&:first-letter': {
                color: redColor,
                fontSize: '1.5rem',
                letterSpacing: '1px',
              },
            },
          ]}
        />
      </MuiBox>

      <MuiBox sx={{ padding: '0 1.5rem' }}>
        <MuiTypography
          text='Nalazimo se na lokaciji'
          sx={[
            textStyle,
            {
              margin: '1.5rem auto',
              fontWeight: 'bold',
              textAlign: 'center',
              fontStyle: 'italic',
              fontSize: {
                xs: '1.2rem',
                sm: '1.5rem',
                md: '1.8rem',
                lg: '2rem',
              },
            },
          ]}
        />
        <iframe
          title='skola'
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2837.7027971026464!2d20.92772087659645!3d44.66442348644394!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4750935e0f794dbf%3A0xbe1a09c56c37c151!2sAuto%20%C5%A1kola%20DU%C5%A0AN%20START!5e0!3m2!1ssr!2srs!4v1714999031493!5m2!1ssr!2srs'
          width='600'
          height='450'
          style={{ width: '100%', borderRadius: '0.5rem' }}
          allowFullScreen=''
          loading='lazy'
          referrerPolicy='no-referrer-when-downgrade'></iframe>
      </MuiBox>
      <MuiBox
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '2rem',
        }}>
        <MuiButton
          variant='outlined'
          text='PRIJAVI SE ONLINE'
          size='large'
          onClick={() => navigate('/checkIn')}
          sx={{
            color: redColor,
            borderColor: redColor,
            fontWeight: 'bold',
            '&:hover': {
              borderColor: redColor,
              boxShadow: `0 0 5px 1px ${redColor}`,
            },
          }}
        />
      </MuiBox>
    </MuiBox>
  );
};

export default AboutUs;

let textStyle = {
  color: greyColorText,
  fontSize: { xs: '1rem', sm: '1rem', md: '1.2rem', lg: '1.2rem' },
  textAlign: 'left',
  marginBottom: '1rem',
};
