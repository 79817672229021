import React from 'react';
import MuiBox from '../../reusableComponents/MuiBox';
import MuiGrid from '../../reusableComponents/MuiGrid';
import MuiTypography from '../../reusableComponents/MuiTypography';
import { redColor, yellowColor } from '../../helper/colors';
import FacebookIcon from '@mui/icons-material/Facebook';
import {
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import logo from '../../assets/logo512.png';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import { Link } from 'react-router-dom';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import { v4 as uuidv4 } from 'uuid';

let toMap = ['Kvalitet', 'Bezbednost', 'Znanje', 'Odgovornost'];

const Footer = () => {
  return (
    <MuiBox sx={{ backgroundColor: yellowColor, margin: '0 0.5rem' }}>
      <MuiGrid
        container
        sx={{}}>
        <MuiGrid
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          sx={{ padding: '1rem' }}>
          <img
            loading='lazy'
            src={logo}
            alt='logo'
            style={{ margin: '0 auto 1.5rem', display: 'block', width: '9rem' }}
          />
          <MuiTypography
            text={'Auto školu DUŠAN START čini:'}
            sx={{
              color: redColor,
              fontWeight: 'bold',
              textAlign: 'center',
              fontSize: {
                xs: '1.3rem',
                sm: '1.3rem',
                md: '1.4rem',
                lg: '1.5rem',
              },
              marginBottom: '1rem',
            }}
          />

          <List
            dense={true}
            sx={{
              width: 'fit-content',
              display: 'block',
              margin: 'auto',
            }}>
            {toMap.map((item) => (
              <ListItem
                key={uuidv4()}
                sx={{
                  paddingLeft: { xs: 0, sm: 0 },
                  position: 'relative',
                  right: { xs: '23px', sm: '23px' },
                }}>
                <ListItemIcon>
                  <TripOriginIcon
                    sx={{
                      fill: '#fff',
                      fontSize: '1.8rem',
                      marginLeft: '1rem',
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={item}
                  primaryTypographyProps={{
                    color: '#fff',
                    fontSize: {
                      xs: '1.2rem',
                      sm: '1.4rem',
                      md: '1.6rem',
                      lg: '1.8rem',
                    },
                  }}
                />
              </ListItem>
            ))}
          </List>
        </MuiGrid>
        <MuiGrid
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          sx={{ padding: '1rem' }}>
          <MuiTypography
            text={'Kontakt'}
            sx={{
              color: redColor,
              marginBottom: '2rem',
              fontWeight: 'bold',
              textAlign: 'center',
              fontSize: {
                xs: '1.8rem',
                sm: '1.8rem',
                md: '2rem',
                lg: '2.2rem',
              },
            }}
          />
          <MuiTypography
            text={'Miloša Velikog 15, Smederevo 11300'}
            sx={{
              color: '#fff',
              textAlign: 'center',
              marginBottom: '1rem',
              fontSize: {
                xs: '1.2rem',
                sm: '1.4rem',
                md: '1.6rem',
                lg: '1.8rem',
              },
            }}
          />

          <div className='centerDiv'>
            <PhoneAndroidIcon
              fontSize='large'
              style={{ fill: '#fff', marginRight: '1rem' }}
            />
            <Hidden smDown>
              <MuiTypography
                text={'+381 66 62-25-333'}
                sx={{
                  color: '#fff',
                  textAlign: 'center',
                  fontSize: {
                    xs: '1.2rem',
                    sm: '1.4rem',
                    md: '1.6rem',
                    lg: '1.8rem',
                  },
                }}
              />
            </Hidden>
            <Hidden smUp>
              <MuiTypography
                text={'+381 66 62-25-333'}
                href={'tel:+381666225333'}
                component='a'
                sx={{
                  color: '#fff',
                  textAlign: 'center',
                  fontSize: {
                    xs: '1.2rem',
                    sm: '1.4rem',
                    md: '1.6rem',
                    lg: '1.8rem',
                  },
                }}
              />
            </Hidden>
          </div>
          <div className='centerDiv'>
            <PhoneAndroidIcon
              fontSize='large'
              style={{ fill: '#fff', marginRight: '1rem' }}
            />
            <Hidden smDown>
              <MuiTypography
                text={'+381 64 36-20-725'}
                sx={{
                  color: '#fff',
                  textAlign: 'center',
                  fontSize: {
                    xs: '1.2rem',
                    sm: '1.4rem',
                    md: '1.6rem',
                    lg: '1.8rem',
                  },
                }}
              />
            </Hidden>
            <Hidden smUp>
              <MuiTypography
                text={'+381 64 36-20-725'}
                href={'tel:+381643620725'}
                component='a'
                sx={{
                  color: '#fff',
                  textAlign: 'center',
                  fontSize: {
                    xs: '1.2rem',
                    sm: '1.4rem',
                    md: '1.6rem',
                    lg: '1.8rem',
                  },
                }}
              />
            </Hidden>
          </div>
          <MuiTypography
            text={'petkoarsic.sd@gmail.com'}
            href='mailto:email@example.com'
            component='a'
            sx={{
              color: '#fff',
              textAlign: 'center',
              display: 'block',
              textDecoration: 'none',
              cursor: 'pointer',
              fontSize: {
                xs: '1.2rem',
                sm: '1.4rem',
                md: '1.6rem',
                lg: '1.8rem',
              },
            }}
          />
        </MuiGrid>
        <MuiGrid
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          sx={{
            padding: '1rem',
            displey: 'flex',
            justifyContent: 'space-around',
            flexDirection: 'column',
          }}>
          <MuiBox
            sx={{
              display: 'flex',
              justifyContent: {
                xs: 'center',
                sm: 'center',
                md: 'center',
                lg: 'center',
              },
              alignItems: 'center',
              order: {
                xs: 2,
                sm: 2,
                md: 1,
                lg: 1,
              },
            }}>
            <IconButton
              size='small'
              title='Posetite nas facebook profil'
              sx={{
                cursor: 'pointer',
                transition: 'all 0.8s ease',
                '&:hover': {
                  transform: 'rotate(360deg)',
                },
              }}
              onClick={() =>
                window.open(
                  'https://www.facebook.com/share/jm65Ft3suPEtuGi1/?mibextid=LQQJ4d',
                  '_blank'
                )
              }>
              <FacebookIcon
                sx={{
                  fontSize: '4rem',
                  color: '#fff',
                  transition: 'all 0.8s ease',
                  '&:hover': {
                    color: redColor,
                  },
                }}
              />
            </IconButton>
            <IconButton
              size='small'
              title='Posetite nas instagram profil'
              sx={{
                cursor: 'pointer',
                transition: 'all 0.8s ease',
                '&:hover': {
                  transform: 'rotate(360deg)',
                },
              }}
              onClick={() =>
                window.open(
                  'https://www.instagram.com/autoskola_dusan_start?igsh=N3YxYXExcWdscjlj&utm_source=qr',
                  '_blank'
                )
              }>
              <InstagramIcon
                sx={{
                  fontSize: '4rem',
                  color: '#fff',
                  transition: 'all 0.8s ease',
                  '&:hover': {
                    color: redColor,
                  },
                }}
              />
            </IconButton>
          </MuiBox>

          <MuiBox
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'baseline',
              marginTop: '1.5rem',
              order: {
                xs: 1,
                sm: 1,
                md: 2,
                lg: 2,
              },
            }}>
            <Link
              to={'/categories/A'}
              className='footerLink'>
              A Kategorija
            </Link>
            <Link
              to={'/categories/B'}
              className='footerLink'>
              B Kategorija
            </Link>
            <Link
              to={'/categories/C'}
              className='footerLink'>
              C Kategorija
            </Link>
          </MuiBox>
        </MuiGrid>
        <MuiGrid
          xs={10}
          sm={10}
          md={10}
          lg={10}
          xl={10}
          sx={{ margin: '1.5rem auto', borderTop: '1px solid grey' }}>
          <MuiTypography
            text={
              'Copyright © 2024 Auto škola DUŠAN START. Sva prava zadržana.'
            }
            sx={{
              fontStyle: 'italic',
              textAlign: 'center',
              marginTop: '1.5rem',
            }}
          />
        </MuiGrid>
      </MuiGrid>
    </MuiBox>
  );
};

export default Footer;
