import grupnaMotori from '../assets/motori/49.webp';
import autoBanner from '../assets/banners/120.webp';
import kamionBanner from '../assets/banners/25.webp';

export let categorieA = {
  banner: grupnaMotori,
  bannerText1: 'Auto Škola DUŠAN START',
  bannerText2: 'Obuka vozača za A kategoriju',
  tabForMap: [
    {
      tabName: 'KATEGORIJA A1',
      textTitle:
        'A1 – kategorija vrši se na motociklima čija radna zepremina motora nije veća od 125ccm i snaga motora do 11kw Auto Škola DUŠAN START obezbeđuje svojim kandidatima: kacigu (svih veličina), potkapu, bluetooth vezu. Uslovi za polaganje: * Kandidat može da se upiše sa 15 godine i završi celokupnu obuku, a praktični ispit može polagati sa navršene 16 godine. * Lična karta je obavezna, potrebno je da je kandidat nosi tokom celokupne obuke.',
      cards: [
        {
          title: 'Kandidat koji ne poseduje vozačku dozvolu:',
          list: [
            '40 časova teorijske nastave',
            'polaganje testa',
            '20 časova praktične obuke',
            'polaganje vožnje',
          ],
        },
        {
          title:
            'Kandidat koji poseduje B kategoriju(a ne poseduje AM kategoriju):',
          list: [
            '7 časova teorijske nastave',
            'polaganje testa',
            '20 časova praktične obuke',
            'polaganje vožnje',
          ],
        },
        {
          title: 'Kandidat koji poseduje AM kategoriju:',
          list: [
            'polaganje testa',
            '7 časova praktične obuke',
            'polaganje vožnje',
          ],
        },
      ],
    },
    {
      tabName: 'KATEGORIJA A2',
      textTitle:
        'A2 – kategorija vrši se na motociklima čija snaga motora nije veca od 35kw. Auto Škola DUŠAN START obezbeđuje svojim kandidatima: kacigu (svih veličina), potkapu, bluetooth vezu. Uslovi za polaganje: * Kandidat može da se upiše sa 17 godine i završi celokupnu obuku, a praktični ispit može polagati sa navršene 18 godine. * Lična karta je obavezna, potrebno je da je kandidat nosi tokom celokupne obuke.',
      cards: [
        {
          title: 'Kandidat koji ne poseduje vozačku dozvolu:',
          list: [
            '40 časova teorijske nastave',
            'polaganje testa',
            '30 časova praktične obuke',
            'polaganje vožnje',
          ],
        },
        {
          title:
            'Kandidat koji poseduje B kategoriju(a ne poseduje AM kategoriju):',
          list: [
            '7 časova teorijske nastave',
            'polaganje testa',
            '30 časova praktične obuke',
            'polaganje vožnje',
          ],
        },
        {
          title: 'Kandidat koji poseduje AM kategoriju:',
          list: [
            'polaganje testa',
            '14 časova praktične obuke',
            'polaganje vožnje',
          ],
        },
        {
          title: 'Kandidat koji poseduje A1 kategoriju:',
          list: [
            'polaganje testa',
            '7 časova praktične obuke',
            'polaganje vožnje',
          ],
        },
      ],
    },

    {
      tabName: 'KATEGORIJA A',
      textTitle:
        'A – kategorija gde spadaju motocikl i teški tricikl čija snaga motora prelazi 15 kW. Auto Škola DUŠAN START obezbeđuje svojim kandidatima: kacigu (svih veličina), potkapu, bluetooth vezu. USLOVI ZA POLAGANJE: Kandidat može da se upiše sa 23 godine i završi celokupnu obuku, a praktični ispit može polagati sa navršene 24 godine.',
      cards: [
        {
          title: 'Kandidat koji ne poseduje vozačku dozvolu:',
          list: [
            '40 časova teorijske nastave',
            'polaganje testa',
            '40 časova praktične obuke',
            'polaganje vožnje',
          ],
        },
        {
          title:
            'Kandidat koji poseduje B kategoriju(a ne poseduje AM kategoriju):',
          list: [
            '7 časova teorijske nastave',
            'polaganje testa',
            '40 časova praktične obuke',
            'polaganje vožnje',
          ],
        },
        {
          title: 'Kandidat koji poseduje AM kategoriju:',
          list: [
            'polaganje testa',
            '20 časova praktične obuke',
            'polaganje vožnje',
          ],
        },
        {
          title: 'Kandidat koji poseduje A1 kategoriju:',
          list: [
            'polaganje testa',
            '14 časova praktične obuke',
            'polaganje vožnje',
          ],
        },
        {
          title: 'Kandidat koji poseduje A2 kategoriju:',
          list: [
            'polaganje testa',
            '7 časova praktične obuke',
            'polaganje vožnje',
          ],
        },
      ],
    },
  ],
};

export let categorieB = {
  banner: autoBanner,
  bannerText1: 'Auto Škola DUŠAN START',
  bannerText2: 'Obuka vozača za B kategoriju',

  tabForMap: [
    {
      tabName: 'KATEGORIJA B',
      textTitle:
        'Auto Škola DUŠAN START obezbeđuje svojim kandidatima moderna putnička vozila za praktičnu obuku i polaganje.',
      cards: [
        {
          title: 'Uslovi za polaganje B kategorije:',
          list: [
            'Kandidat može da se upiše sa 16 godine i završi celokupnu obuku, a praktični ispit može polagati sa navršene 17 godine.',
            'Lična karta je obavezna, potrebno je da je kandidat nosi tokom celokupne obuke',
          ],
        },
        {
          title: 'Trajanje obuke za B kategoriju:',
          list: [
            '40 časova teoretske obuke',
            'polaganje testa',
            '40 časova praktične obuke (najviše 2 časa dnevno), 1 čas praktične obuke traje 45 minuta.',
            'polaganje praktičnog dela',
          ],
        },
        {
          title:
            'Uslovi za polaganje B kategorije ukoliko kandidat poseduje AM ili A1 kategoriju:',
          list: [
            'Teorijska nastava ukupno 7 časova',
            'Praktična obuka 35 časova',
          ],
        },
        {
          title:
            'Uslovi za polaganje B kategorije ukoliko kandidat poseduje A ili A2 kategoriju:',
          list: [
            'Teorijska nastava ukupno 7 časova',
            'Praktična obuka 35 časova',
          ],
        },
      ],
    },
  ],
};
export let categorieC = {
  banner: kamionBanner,
  bannerText1: 'Auto Škola DUŠAN START',
  bannerText2: 'Obuka vozača za C kategoriju',

  tabForMap: [
    {
      tabName: 'KATEGORIJA C',
      textTitle: [
        'Vozila čija najveća dozvoljena masa prelazi 12000 kg',
        'Dužine veća od 8 m',
        'Širina veća od 2.4 m',
        'Najveća konstruktivna brzina veća od 80 km/h',
        'Koji ima zatvoreni tovarni sanduk',
        'Priključno vozilo dužina najmanja 7.5 m sa zatvorenim tovarnim sandukom',
      ],
      cards: [
        {
          title: 'Uslovi za polaganje C kategorije:',
          list: [
            'Kandidat može da se upiše sa 20 godine i završi celokupnu obuku, a praktični ispit može polagati sa navršene 21 godine.',
            'Posedovanje vozačke dozvole B kategorije (ne sme biti probna)',
          ],
        },
        {
          title: 'Trajanje obuke za C kategoriju:',
          list: [
            '7 časova teoretske obuke',
            'polaganje testa',
            '15 časova praktične obuke',
            'polaganje praktičnog dela',
          ],
        },
      ],
    },
    {
      tabName: 'KATEGORIJA CE',
      textTitle: [
        'Vozila čija najveća dozvoljena masa prelazi 12000 kg',
        'Dužine veća od 8 m',
        'Širina veća od 2.4 m',
        'Najveća konstruktivna brzina veća od 80 km/h',
        'Koji ima zatvoreni tovarni sanduk',
        'Priključno vozilo dužina najmanja 7.5 m sa zatvorenim tovarnim sandukom',
      ],
      cards: [
        {
          title: 'Uslovi za polaganje CE kategorije:',
          list: ['Posedovanje vozačke dozvole C kategorije'],
        },
        {
          title: 'Trajanje obuke za CE kategoriju:',
          list: ['7 časova praktične obuke', 'polaganje praktičnog dela'],
        },
      ],
    },
  ],
};
