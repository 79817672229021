import React from 'react';
import MuiBox from '../../../reusableComponents/MuiBox';
import MuiTypography from '../../../reusableComponents/MuiTypography';
import { redColor, yellowColor } from '../../../helper/colors';
import MuiButton from '../../../reusableComponents/MuiButton';
import MuiGrid from '../../../reusableComponents/MuiGrid';
import { v4 as uuidv4 } from 'uuid';
import { Chip, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ucionica from '../../../assets/banners/ucionica1.webp';
import ucionica2 from '../../../assets/ucionica/ucionica2.webp';
import ucionica3 from '../../../assets/ucionica/ucionica3.webp';

import { Helmet } from 'react-helmet';

const Classroom = () => {
  const navigate = useNavigate();
  return (
    <MuiBox sx={{ padding: '0 0.5rem' }}>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Učionica - Auto Škola DUŠAN START</title>
        <meta
          name='description'
          content='Pogledajte našu modernu učionicu u Auto školi DUŠAN START u Smederevu, opremljenu za kvalitetnu teorijsku obuku budućih vozača.'
        />
        <meta
          name='keywords'
          content='auto škola, učionica, Dušan Start, Smederevo, teorijska obuka, moderna učionica, kvalitetna obuka'
        />
        <meta
          name='author'
          content='Marko Stojanović'
        />
        <link
          rel='canonical'
          href='https://www.dusanstartautoskola.rs/classroom'
        />
        {/* Open Graph Meta Tagovi */}
        <meta
          property='og:title'
          content='Učionica - Auto Škola DUŠAN START'
        />
        <meta
          property='og:description'
          content='Pogledajte našu modernu učionicu u Auto školi DUŠAN START u Smederevu, opremljenu za kvalitetnu teorijsku obuku budućih vozača.'
        />
        <meta
          property='og:image'
          content='https://www.dusanstartautoskola.rs/logo512.png'
        />
        <meta
          property='og:url'
          content='https://www.dusanstartautoskola.rs/classroom'
        />
        <meta
          property='og:type'
          content='website'
        />
        <meta
          property='og:site_name'
          content='Auto Škola DUŠAN START'
        />
        <meta
          property='og:locale'
          content='sr_RS'
        />

        {/* Twitter Card Meta Tagovi */}
        <meta
          name='twitter:card'
          content='summary_large_image'
        />
        <meta
          name='twitter:title'
          content='Učionica - Auto Škola DUŠAN START'
        />
        <meta
          name='twitter:description'
          content='Pogledajte našu modernu učionicu u Auto školi DUŠAN START u Smederevu, opremljenu za kvalitetnu teorijsku obuku budućih vozača.'
        />
        <meta
          name='twitter:image'
          content='https://www.dusanstartautoskola.rs/logo512.png'
        />
        <meta
          name='twitter:site'
          content='@twitterhandle'
        />
        <meta
          name='twitter:creator'
          content='@twitterhandle'
        />
      </Helmet>

      <MuiBox
        sx={{
          backgroundImage: `url(${ucionica})`,
          backgroundSize: 'cover',
          height: { xs: '400px', sm: '500px', md: '600px', lg: '600px' },
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}></MuiBox>
      <MuiBox sx={{ padding: '2rem' }}>
        <MuiTypography
          text='Učionica nase skole je klimatizovana, moderna i savremena i kao takva ona će omogućiti svim našim kandidatima da se osećaju lagodno i koncentrisano u toku edukacije i polaganja samih ispita. Učionica poseduje nove računare, projektor, tablu i opremu za polaganje vozačkog ispita'
          sx={{
            color: '#000',
            fontSize: {
              xs: '1rem',
              sm: '1.2rem',
              md: '1.5rem',
              lg: '1.5rem',
            },
            textAlign: 'center',
            marginBottom: '1.5rem',
            '&:first-letter': {
              color: yellowColor,
              letterSpacing: '1px',
              fontSize: {
                xs: '1.2rem',
                sm: '1.4rem',
                md: '1.7rem',
                lg: '1.7rem',
              },
            },
          }}
        />

        <Divider sx={{ margin: '1rem auto' }}>
          <Chip
            label=' Učionica nase skole '
            sx={{
              height: '2rem',
              '& .MuiChip-label': {
                fontSize: '1.3rem',
                padding: '1rem',
              },
            }}
            size='small'
          />
        </Divider>

        <MuiGrid
          container
          spacing={5}
          sx={{
            padding: '1.5rem',
            justifyContent: 'space-around',
            alignItems: 'center',
            display: 'flex',
          }}>
          {[ucionica2, ucionica3].map((item) => (
            <MuiGrid
              xs={12}
              sm={6}
              md={4}
              lg={4}
              xl={4}
              key={uuidv4()}
              sx={{}}>
              <img
                loading='lazy'
                src={item}
                alt='classromm'
                style={{ width: '100%', margin: 'auto', display: 'block' }}
              />
            </MuiGrid>
          ))}
        </MuiGrid>
      </MuiBox>
      <MuiBox
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '2rem',
        }}>
        <MuiButton
          variant='outlined'
          text='PRIJAVI SE ONLINE'
          size='large'
          onClick={() => navigate('/checkIn')}
          sx={{
            color: redColor,
            borderColor: redColor,
            fontWeight: 'bold',
            '&:hover': {
              borderColor: redColor,
              boxShadow: `0 0 5px 1px ${redColor}`,
            },
          }}
        />
      </MuiBox>
    </MuiBox>
  );
};

export default Classroom;
