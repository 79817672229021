import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import NavBar from './components/NavBar/NavBar';
import HomePage from './components/HomePage/HomePage';
import ContactUs from './components/ContactUs/ContactUs';
import Categories from './components/Categories/Categories';
import AboutUs from './components/AboutCarSchool/AboutUs/AboutUs';
import OurTeam from './components/AboutCarSchool/OutTeam/OurTeam';
import Classroom from './components/AboutCarSchool/Classroom/Classroom';
import Vehicles from './components/AboutCarSchool/Vehicles/Vehicles';
import CheckIn from './components/CheckIn/CheckIn';
import SigninUp from './components/DriverTraining/SigninUp/SigninUp';
import TheoreticalTraining from './components/DriverTraining/TheoreticalTraining/TheoreticalTraining';
import MedicalExamination from './components/DriverTraining/MedicalExamination/MedicalExamination';
import PracticalTraining from './components/DriverTraining/PracticalTraining/PracticalTraining';
import FirstAid from './components/DriverTraining/FirstAid/FirstAid';
import AdditionalClasses from './components/DriverTraining/AdditionalClasses/AdditionalClasses';
import Footer from './components/Footer/Footer';
import ErrorPage from './components/ErrorPage/ErrorPage';
import ToastNotification from './components/ToastNotification';
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();
const App = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [location.pathname]);

  return (
    <>
      <NavBar location={location}>
        <Routes>
          <Route
            path='/'
            element={<HomePage />}
          />
          <Route
            path='/aboutUs'
            element={<AboutUs />}
          />
          <Route
            path='/ourTeam'
            element={<OurTeam />}
          />
          <Route
            path='/classroom'
            element={<Classroom />}
          />
          <Route
            path='/vehicles'
            element={<Vehicles />}
          />
          <Route
            path='/categories/:id'
            element={<Categories />}
          />
          <Route
            path='/contact'
            element={<ContactUs />}
          />
          <Route
            path='/checkIn'
            element={<CheckIn location={location} />}
          />
          <Route
            path='/signinUp'
            element={<SigninUp />}
          />
          <Route
            path='/theoreticalTraining'
            element={<TheoreticalTraining />}
          />
          <Route
            path='/medicalExamination'
            element={<MedicalExamination />}
          />
          <Route
            path='/practicalTraining'
            element={<PracticalTraining />}
          />
          <Route
            path='/firstAid'
            element={<FirstAid />}
          />
          <Route
            path='/additionalClasses'
            element={<AdditionalClasses />}
          />
          <Route
            path='*'
            element={<ErrorPage />}
          />
        </Routes>
      </NavBar>
      <Footer />
      <ToastNotification />
    </>
  );
};

export default App;
