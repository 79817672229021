import React from 'react';
import MuiBox from '../../../reusableComponents/MuiBox';
import MuiButton from '../../../reusableComponents/MuiButton';
import MuiTypography from '../../../reusableComponents/MuiTypography';
import { greyColorText, redColor, yellowColor } from '../../../helper/colors';
import { useNavigate } from 'react-router-dom';
import banner from '../../../assets/banners/81.webp';
import { Helmet } from 'react-helmet';

const AdditionalClasses = () => {
  const navigate = useNavigate();
  return (
    <MuiBox sx={{ padding: '0 0.5rem' }}>
      <Helmet>
        <title>Dodatni časovi vožnje - Auto škola DUŠAN START</title>
        <meta charSet='utf-8' />
        <meta
          name='description'
          content='Prijavite se za dodatne časove vožnje u Auto školi DUŠAN START u Smederevu i unapredite svoje veštine vožnje. Naši stručni instruktori će vam pomoći da postanete bolji vozač.'
        />
        <meta
          name='keywords'
          content='auto škola, Dušan Start, Smederevo, dodatni časovi vožnje, vozačke veštine, instruktori, vozačka obuka'
        />
        <meta
          name='author'
          content='Marko Stojanovic'
        />
        <link
          rel='canonical'
          href='https://www.dusanstartautoskola.rs/additionalClasses'
        />

        <meta
          property='og:title'
          content='Dodatni časovi vožnje - Auto škola DUŠAN START'
        />
        <meta
          property='og:description'
          content='Poboljšajte svoje veštine vožnje uz dodatne časove u Auto školi DUŠAN START u Smederevu. Naši iskusni instruktori vam pomažu da postanete sigurniji vozač.'
        />
        <meta
          property='og:image'
          content='https://www.dusanstartautoskola.rs/logo512.png' // Uključiti stvarni URL
        />
        <meta
          property='og:url'
          content='https://www.dusanstartautoskola.rs/additionalClasses'
        />
        <meta
          property='og:type'
          content='website'
        />
        <meta
          property='og:site_name'
          content='Auto škola DUŠAN START'
        />
        <meta
          property='og:locale'
          content='sr_RS'
        />

        <meta
          name='twitter:card'
          content='summary_large_image'
        />
        <meta
          name='twitter:title'
          content='Dodatni časovi vožnje - Auto škola DUŠAN START'
        />
        <meta
          name='twitter:description'
          content='Poboljšajte svoje veštine vožnje uz dodatne časove u Auto školi DUŠAN START. Naši stručni instruktori će vam pomoći da postanete bolji vozač.'
        />
        <meta
          name='twitter:image'
          content='https://www.dusanstartautoskola.rs/logo512.png' // Uključiti stvarni URL
        />
        <meta
          name='twitter:site'
          content='@twitterhandle'
        />
        <meta
          name='twitter:creator'
          content='@twitterhandle'
        />
      </Helmet>

      <MuiBox
        sx={{
          backgroundImage: `url(${banner})`,
          backgroundSize: 'cover',
          height: { xs: '400px', sm: '500px', md: '600px', lg: '600px' },
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}>
        <MuiTypography
          text='Oslobodite se straha podignite samopouzdanje'
          sx={{
            color: '#fff',
            fontWeight: 'bold',
            fontSize: { xs: '1.2rem', sm: '1.2rem', md: '1.5rem', lg: '2rem' },
            textAlign: 'center',
            marginBottom: '1.5rem',
            textShadow: '2px 2px 5px rgba(0, 0, 0, 0.7)',
            WebkitTextStroke: '1px black',
          }}
        />
        <MuiTypography
          text='Časovi vožnje'
          sx={{
            color: '#fff',
            fontWeight: 'bold',
            fontSize: { xs: '1.5rem', sm: '1.5rem', md: '2rem', lg: '2.3rem' },
            textAlign: 'center',
            marginBottom: '1.5rem',
            textShadow: '2px 2px 5px rgba(0, 0, 0, 0.7)',
            WebkitTextStroke: '1px black',
          }}
        />
        <MuiTypography
          text='Profesionalni instruktori sa višegodišnjim iskustvom u obuci'
          sx={{
            color: '#fff',
            fontWeight: 'bold',
            fontSize: { xs: '1.2rem', sm: '1.2rem', md: '1.5rem', lg: '2rem' },
            textAlign: 'center',
            textShadow: '2px 2px 5px rgba(0, 0, 0, 0.7)',
            WebkitTextStroke: '1px black',
          }}
        />
      </MuiBox>
      <MuiBox
        sx={{
          width: {
            xs: '95%',
            sm: '90%',
            md: '80%',
            lg: '80%',
          },
          margin: '2rem auto',
        }}>
        <MuiTypography
          text='Oslobodite se straha podignite samopouzdanje'
          sx={[
            textStyle,
            {
              color: yellowColor,
              fontWeight: 'bold',
              textAlign: 'center',
              fontSize: {
                xs: '1.2rem',
                sm: '1.2rem',
                md: '1.5rem',
                lg: '2rem',
              },
            },
          ]}
        />
        <MuiTypography
          text='Oslobodite se straha, podignite samopouzdanje. Pomoćićemo vam da obnovite vozačke veštine na dodatnim časovima vožnje.'
          sx={textStyle}
        />
        <MuiTypography
          text='Želimo da vam pomognemo da se oslobodite početničnog straha, da uvećate svoje samopouzdanje, da se oslobodite treme i briga. Siguran vozač je pouzdan vozač.'
          sx={textStyle}
        />
        <MuiTypography
          text='Pomoći ćemo vam da obnovite svoje vozačke veštine, da savladate parkiranje i aktivnose se uključite u saobračaj.'
          sx={textStyle}
        />
        <MuiTypography
          text='U dogovoru sa našim instruktorima možete unapred zakazati termine dodatnih časova koji vama najviše odgovaraju.'
          sx={textStyle}
        />
      </MuiBox>
      <MuiBox
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '2rem',
        }}>
        <MuiButton
          variant='outlined'
          text='PRIJAVI SE ONLINE'
          size='large'
          onClick={() => navigate('/checkIn')}
          sx={{
            color: redColor,
            borderColor: redColor,
            fontWeight: 'bold',
            '&:hover': {
              borderColor: redColor,
              boxShadow: `0 0 5px 1px ${redColor}`,
            },
          }}
        />
      </MuiBox>
    </MuiBox>
  );
};

export default AdditionalClasses;

let textStyle = {
  color: greyColorText,
  fontSize: { xs: '1rem', sm: '1rem', md: '1.2rem', lg: '1.2rem' },
  textAlign: 'left',
  marginBottom: '1rem',
};
