import React from 'react';
import MuiGrid from '../../reusableComponents/MuiGrid';
import prva from '../../assets/koraciPrilikomObuke/1.jpg';
import druga from '../../assets/koraciPrilikomObuke/2.jpg';
import treca from '../../assets/koraciPrilikomObuke/3.jpg';
import cetvrta from '../../assets/koraciPrilikomObuke/4.jpg';
import peta from '../../assets/koraciPrilikomObuke/5.jpg';
import sesta from '../../assets/koraciPrilikomObuke/6.jpg';
import { v4 as uuidv4 } from 'uuid';
import MuiCard from '../../reusableComponents/MuiCard';
import { CardContent } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import MuiBox from '../../reusableComponents/MuiBox';
import { greyColorText } from '../../helper/colors';

const StepsForTrain = () => {
  const navigate = useNavigate();
  let toMap = [
    {
      img: prva,
      text: 'Korak 1 - Teorijska nastava',
      route: '/theoreticalTraining',
    },
    {
      img: druga,
      text: 'Korak 2 - Teorijski ispit',
      route: '/theoreticalTraining',
    },
    {
      img: treca,
      text: 'Korak 3 - Lekarsko uverenje',
      route: '/medicalExamination',
    },
    {
      img: cetvrta,
      text: 'Korak 4 - Praktična obuka',
      route: '/practicalTraining',
    },
    { img: peta, text: 'Korak 5 - Prva pomoć', route: '/firstAid' },
    {
      img: sesta,
      text: 'Korak 6 - Praktičan ispit',
      route: '/practicalTraining',
    },
  ];
  return (
    <MuiGrid
      container
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        padding: {
          xs: 0,
          sm: '0 1rem',
          md: '0 1.5rem',
          lg: '0 2rem',
        },
        width: '100%',
      }}
      spacing={4}>
      {toMap.map((data) => (
        <MuiGrid
          xs={11}
          sm={6}
          md={4}
          lg={4}
          xl={4}
          key={uuidv4()}
          data-aos='zoom-in-down'
          data-aos-easing='linear'
          data-aos-duration='500'>
          <MuiCard
            sx={{
              boxShadow: '12px 11px 13px 5px #0000005c',
              transition: 'all 0.5s linear',
              '&:hover': {
                transform: 'scale(1.15)',
                boxShadow: '0px 0px 13px 5px #0000005c',
              },
            }}>
            <MuiBox
              sx={{
                transition: 'all 0.5s linear',
                overflow: 'hidden',
                '&:hover': {
                  transform: 'scale(1.33)',
                },
              }}>
              <img
                src={data.img}
                style={{ width: '100%', margin: 'auto', cursor: 'pointer' }}
                alt={`step img ${data.text}`}
                onClick={() => navigate(data.route)}
              />
            </MuiBox>
            <CardContent
              sx={{
                backgroundColor: '#cccccc3b',
                position: 'relative',
                zIndeks: 55,
              }}>
              <Link
                to={data.route}
                style={{
                  display: 'block',
                  textAlign: 'center',
                  textDecoration: 'none',
                  color: greyColorText,
                  fontWeight: 'bold',
                  letterSpacing: '2px',
                }}>
                {data.text}
              </Link>
            </CardContent>
          </MuiCard>
        </MuiGrid>
      ))}
    </MuiGrid>
  );
};

export default StepsForTrain;
