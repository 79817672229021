import React from 'react';
import Slider from '../reusableComponents/Slider';
import { v4 as uuidv4 } from 'uuid';
import MuiTypography from '../reusableComponents/MuiTypography';
import { redColor } from '../helper/colors';
import auto1 from '../assets/slider/auto1.webp';
import auto2 from '../assets/slider/auto2.webp';
import auto3 from '../assets/slider/auto3.webp';
import auto4 from '../assets/slider/auto4.webp';
import auto5 from '../assets/slider/auto5.webp';
import auto134 from '../assets/slider/134.webp';
import auto98 from '../assets/slider/98.webp';
import auto117 from '../assets/slider/117.webp';

const DriveParkSLider = () => {
  let driveImgArray = [
    auto4,
    auto2,
    auto3,
    auto5,
    auto1,
    auto134,
    auto98,
    auto117,
  ];
  const responsiveSettings = [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 200,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ];
  return (
    <div style={{}}>
      <MuiTypography
        text='NAŠ VOZNI PARK'
        sx={{
          color: redColor,
          fontWeight: 'bold',
          fontSize: { xs: '1.5rem', sm: '1.7rem', md: '1.9rem', lg: '2.2rem' },
          textAlign: 'center',
          padding: '1.5rem 0',
          margin: '0 auto',
        }}
      />
      <Slider
        slidesToScroll={2}
        slidesToShow={2}
        responsive={responsiveSettings}>
        {driveImgArray.map((item) => (
          <div
            className='each-slide-effect'
            key={uuidv4()}>
            <img
              loading='lazy'
              src={item}
              alt='slider car img'
              style={{
                width: '100%',
                display: 'block',
                margin: 'auto',
                height: '100%',
              }}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default DriveParkSLider;
