import { MenuItem, TextField } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { greyColorText } from '../helper/colors';
import MuiTypography from './MuiTypography';

const currencies = [
  {
    value: 'A kategorija',
    label: 'Obuka vozača za polaganje A kategorije',
  },
  {
    value: 'A1 kategorija',
    label: 'Obuka vozača za polaganje A1 kategorije',
  },
  {
    value: 'A2 kategorija',
    label: 'Obuka vozača za polaganje A2 kategorije',
  },
  {
    value: 'AM kategorija',
    label: 'Obuka vozača za polaganje AM kategorije',
  },
  {
    value: 'B kategorija',
    label: 'Obuka vozača za polaganje B kategorije',
  },
  {
    value: 'C kategorija',
    label: 'Obuka vozača za polaganje C kategorije',
  },
  {
    value: 'CE kategorija',
    label: 'Obuka vozača za polaganje CE kategorije',
  },
];

const FormInputHook = ({
  name,
  control,
  label,
  multiline,
  validation,
  errors,
  selectField,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={
        selectField === true
          ? { required: 'Morate izabrati kategoriju!' }
          : validation
      }
      render={({ field }) => (
        <>
          {selectField === true ? (
            <TextField
              size='small'
              fullWidth
              error={errors[name] ? true : false}
              select
              label={label}
              sx={{
                '& label.Mui-focused': {
                  color: greyColorText,
                },

                ' & .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: greyColorText,
                  },
                },
              }}
              {...field}>
              {currencies.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          ) : (
            <TextField
              size='small'
              fullWidth
              error={errors[name] ? true : false}
              label={label}
              multiline={multiline}
              minRows={multiline ? 6 : 0}
              maxRows={multiline ? 10 : 0}
              variant='outlined'
              sx={{
                '& label.Mui-focused': {
                  color: greyColorText,
                },

                ' & .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: greyColorText,
                  },
                },
              }}
              {...field}
            />
          )}
          <MuiTypography
            variant='caption'
            color={errors[name] ? 'error' : 'transparent'}
            paragraph
            mt={0.5}
            mb={0}
            sx={{ width: '100%' }}
            align='center'
            text={errors[name] ? errors[name]?.message : 'Error Message'}
          />
        </>
      )}
    />
  );
};

export default FormInputHook;
